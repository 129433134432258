export const promptsBackup = {
  competitionPrompt: {
    competitors: "competitors in the first column,",
    description: " description in the 2 column, ",
    facts: " and top 5 Positive and negative facts in 4th column.",
    topAttributes: "top 5 positive and negative attributes in third column,",
  },
  competitionPromptPrefix: "Create a table to structure the information with",
  dimensionsPrompt: {
    recommendedBuying: "",
    sources:
      "Provide a comprehensive list of the specific sources utilized in your response in a bulleted list separate from your main response. must provide authentic and accessible links to the sources. In case of unavailability or broken links, indicate this in the list for transparency.",
    textInput: "",
  },
  dimensionsPromptPrefix:
    "List the top 5 decision criteria or considerations that are important for end customer (no more than 5) Please provide responses in bullet point form and in order of importance with the most important first. ",
  favorabilityPrompt: {
    competitorComparison:
      "2) competitors and their top 5 positive and negative attributes provided in a table form,",
    sources:
      "3)Provide a comprehensive list of the specific sources utilized in your response in a bulleted list separate from your main response. must provide authentic and accessible links to the sources. In case of unavailability or broken links, indicate this in the list for transparency",
    topAttributes:
      "1) Top 5 positive and negative attributes in bulleted form,",
  },
  favorabilityPromptPrefix: "Please provide the following for",
  globalEndPrompt:
    "Do not send data related to words like 'Sure' and 'I hope'. Be brief. Provide all info in markdown (.md) format only",
  globalStartPrompt: "I am an end consumer looking for information on a",
  keyPromptPrefix: "",
  keyPromptSuffix:
    "Please Don't send me incomplete data and Please don't give me any wrong information in response.",
  keyPromptSources: "112",
  leadershipPrompt: {
    bestCategory: "Provide best brands in this category.",
    sources:
      "Provide a comprehensive list of the specific sources utilized in your response in a bulleted list separate from your main response. must provide authentic and accessible links to the sources. In case of unavailability or broken links, indicate this in the list for transparency.",
    textInput: "",
    topCategory: "Provide top 5 brands in this category.",
  },
  leadershipPromptPrefix: "Please provide responses in bullet point format.",
  overviewPrompt: {
    attributes: "2) main brand attributes,",
    competetiveSet: "3) competitive set.",
    description: "1) brand description,",
    productAttributes: "2) product attributes,",
    productDescription: "1) product description,",
    sources:
      "Provide a comprehensive list of the specific sources utilized in your response in a bulleted list separate from your main response. must provide authentic and accessible links to the sources. In case of unavailability or broken links, indicate this in the list for transparency.",
  },
  overviewPromptPrefix: "Provide the following for",
  productDimensionsPrompt: {
    attributes: "",
    description: "",
    productDimensionsPrompt: "provide product dimensions.",
    sources: "",
  },
  productDimensionsPromptPrefix:
    "Please provide responses in bullet point form.",
  reachPrompt: {
    bestCategory: "",
    sources:
      "Provide a comprehensive list of the specific sources utilized in your response in a bulleted list separate from your main response. must provide authentic and accessible links to the sources. In case of unavailability or broken links, indicate this in the list for transparency.",
    textInput: "",
    topCategory:
      "Please provide top 5 products in this category.Please provide responses in bullet point form.",
  },
  reachPromptPrefix: "",
  representationPrompt: {
    attributes: "Provide attributesof product.",
    description: "Provide brief description of product.",
    sources:
      "Provide a comprehensive list of the specific sources utilized in your response in a bulleted list separate from your main response. Provide link to the source if possible",
  },
  representationPromptPrefix: "Please provide responses in bullet point form.",
};

export const monitoringPromptsBackup = {
  Indexing: "",
  baselineTablePrompt:
    "Create a tabular format with two columns from the given markdown raw string data. The first column should have no header, and the second column should have the header name 'Baseline'. Do not modify the provided markdown data, and ensure that no additional row headers are added. Also, ensure that no truncation or alterations occur in the markdown data. row header name  in the table should correspond to the main title found in the markdown data, and the information under main title should be matched to the 'Baseline' column.",
  brandIndexPrompt: {
    brandIndexScore: "",
    competitorBrandIndexScore: "",
  },
  brandIndexPromptPrefix: "",
  campaignPrompt: "",
  competitionPrompt: {
    competetiveSet: "Competitive Set.",
    topNegativeAttributes: "Top 5 Negative Attributes of Competitors.",
    topPositiveAttributes: "Top 5 Positive Attributes of Competitors.",
  },
  competitionPromptPrefix:
    "Please provide genuine and detailed information organized under the below main title., Ensure that all information is presented in bullet format without additional headers, subheaders, or child titles. Keep the content concise and provide a clear separation between main title and their information sections...main title is:",
  dataComparisonPrompt:
    'You are tasked with extracting the major key differences between two sets of data provided in Markdown string format. One set represents past data, termed as the "Baseline," while the other set represents the recent data, termed as the "Latest Version." Your objective is to identify and articulate the significant differences between these datasets, avoiding line-by-line comparison. Specifically, focus on substantial alterations in content while disregarding minor wording changes that preserve the same meaning. Once identified, organize these key differences under the respective main title present in each dataset. Your output should highlight the distinct modifications between the Baseline and Latest Version datasets concisely and categorically.',
  dataDaviationPrompt:
    "Given a set of previous responses and a recent response below, calculate the percentage of unique data present in the recent response that is not mentioned in any of the previous responses. Ensure that uniqueness is determined not just by differing words, but also by distinct meaning. Provide a mathematical calculation illustrating how the AI determines this deviation...",
  dimensionsPrompt: {
    recommendedBuying:
      "**Frequently recommended buying criteria/decision:** -[Information]. ",
  },
  dimensionsPromptPrefix:
    "Please provide genuine and detailed information organized under the below main title., Ensure that all information is presented in bullet format without additional headers, subheaders, or child titles. Keep the content concise and provide a clear separation between main title and their information sections...main title is:",
  frequencyPrompt: "",
  globalEndPrompt:
    "Do not send data related to words like 'Sure' and 'I hope'. Be brief. ",
  globalStartPrompt: "I am an end consumer looking for information on a",
  hallucinationPrompt: "",
  keyPromptPrefix: "",
  keyPromptSources:
    "Please provide the genuine hyperlinks to the sources from which you are extracting this information. Add the sources under Main header 'Sources' in bullet list format.",
  keyPromptSuffix:
    "Please Don't send me incomplete data and Please don't give me any wrong information in response.",
  leadershipPrompt: {
    topCategory:
      "**Top 5 brands in category:**    - [brand 1]    - [brand 2]    - [brand 3]    - [brand 4]    - [brand 5].",
    topProductCategory:
      "**Top 5 products in category:**    - [product 1]    - [product  2]    - [product  3]    - [product  4]    - [product 5]",
  },
  leadershipPromptPrefix:
    "Please provide genuine and detailed information organized under the below main title., Ensure that all information is presented in bullet format without additional headers, subheaders, or child titles. Keep the content concise and provide a clear separation between main title and their information sections...main title is:",
  monitoringTablePrompt:
    "Given a Markdown table with 'baseline' column and row headers, integrate a new dataset with identical row headers. Dynamically generate additional column such as 'week1' if last column is 'baseline' and 'week2' if last column is 'week1' and so on., appending them to the header. Populate these new column with corresponding information from the new dataset. Ensure that the updated table preserves the original structure, including added column and updated information. Locate all data within the specified table format, strictly avoid any information outside it. Re-check for accuracy in your implementation.",
  overviewPrompt: {
    description:
      "**Brand Description:**    - [Provide brand description details here] ",
    productDescription:
      "**Product Description:**    - [Provide Product description details here] ",
    topNegativeAttributes:
      "**Top 5 Negative Attributes:**    - [Negative Attribute 1]    - [Negative Attribute 2]    - [Negative Attribute 3]    - [Negative Attribute 4]    - [Negative Attribute 5].",
    topNegativeStatements:
      "**Top 5 Negative Statements:**    - [Negative Statement 1]    - [Negative Statement 2]    - [Negative Statement 3]    - [Negative Statement 4]    - [Negative Statement 5].",
    topPositiveAttributes:
      "**Top 5 Positive Attributes:**    - [Positive Attribute 1]    - [Positive Attribute 2]    - [Positive Attribute 3]    - [Positive Attribute 4]    - [Positive Attribute 5].",
    topPositiveStatements:
      "**Top 5 Positive Statements:**    - [Positive Statement 1]    - [Positive Statement 2]    - [Positive Statement 3]    - [Positive Statement 4]    - [Positive Statement 5].",
  },
  overviewPromptPrefix:
    "Please provide genuine and detailed information organized under the below main title., Ensure that all information is presented in bullet format without additional headers, subheaders, or child titles. Keep the content concise and provide a clear separation between main title and their information sections...main title is:",
  productAttributesPromptPrefix:
    "Please provide genuine and detailed information organized under the following main titles:  1. **Product Description:**    - [Provide product description details here]  2. **Top 5 Positive and Negative Attributes:**    - [Positive Attribute 1]    - [Positive Attribute 2]    - [Positive Attribute 3]    - [Positive Attribute 4]    - [Positive Attribute 5]    - [Negative Attribute 1]    - [Negative Attribute 2]    - [Negative Attribute 3]    - [Negative Attribute 4]    - [Negative Attribute 5]  Ensure that all information is presented in bullet format without additional headers, subheaders, or child titles. Keep the content concise and provide a clear separation between main titles and their information sections.",
  sources: {
    competetiveSet: "Competitive Set.",
    description: "Brand Description.",
    productDescription: "Product Description.",
    recommendedBuying: "Frequently recommended buying criteria/considerations.",
    topAttributes: "Top 5 Positive and Negative Attributes .",
    topCategory: "Top 5 Brands in Category.",
    topNegativeAttributes: "Top 5 Negative Attributes.",
    topPositiveAttributes: "Top 5 Positive Attributes.",
    topProductCategory: "Top 5 Products in Category.",
  },
  sourcesPrefix:
    "Provide authenticated hyperlinks for the below attribute related to [Brand/Product/category].,  Ensure that the hyperlinks are reliable and directly correspond to each specified attribute...attribute is:",
  synthesizationPrompt:
    " Combine and aggregate data from multiple markdown raw strings under a main title. Ensure that the final result contains a cumulative set of unique information without duplication. Additionally, analyze the position of attributes within the responses and determine their strength based on consistency of placement. If a specific attribute consistently appears at the top of each individual data, its strength is 100%. If the position varies across responses, the strength is calculated as a percentage relative to its frequency and placement within the response. Under the main title, include its corresponding aggregated information alongside the strength of each attribute based on its position consistency, listed in bullet format:",
  positiveAttributesAnalysis: "abc",
  negativeAttributesAnalysis: "abc",
  positiveFactsAnalysis: "abc",
  negativeFactsAnalysis: "abc",
  topBrandsAnalysis: "abc",
  topProductsAnalysis: "abc",
  buyingAnalysis: "abc",
};
export const promptNamesForDB = {
  "Start prompt with": "globalStartPrompt",
  "End prompt with": "globalEndPrompt",
  "Key Prompt Prefix": "keyPromptPrefix",
  "Key Prompt Suffix": "keyPromptSuffix",
  "Key Prompt Sources": "keyPromptSources",
  "Brand/Product Overview": "overviewPromptPrefix",
  "Brand/Product Favorability": "favorabilityPromptPrefix",
  "Product Dimensions": "productDimensionsPromptPrefix",
  "Product Reach": "reachPromptPrefix",
  "Brand Category Dimensions": "dimensionsPromptPrefix",
  "Category Leadership": "leadershipPromptPrefix",
  Competition: "competitionPromptPrefix",
};

export const monitorPromptNamesForDB = {
  "Start prompt with": "globalStartPrompt",
  "End prompt with": "globalEndPrompt",
  "Key Prompt Prefix": "keyPromptPrefix",
  "Key Prompt Suffix": "keyPromptSuffix",
  "Key Prompt Sources": "keyPromptSources",
  "Brand/Product Overview and Favorability": "overviewPromptPrefix",
  "Product Attributes": "productAttributesPromptPrefix",
  "Category Dimensions": "dimensionsPromptPrefix",
  "Category Leadership": "leadershipPromptPrefix",
  Competition: "competitionPromptPrefix",
  Sources: "sourcesPrefix",
  "Brand Index Score": "brandIndexPromptPrefix",
  "Hallucination Detection": "hallucinationPrompt",
  Indexing: "Indexing",
  "Campaign Messages": "campaignPrompt",
  Frequency: "frequencyPrompt",
  "Unique Data Synthesization": "synthesizationPrompt",
  "Data Comparison": "dataComparisonPrompt",
  "Monitoring Table": "monitoringTablePrompt",
  "Baseline Table": "baselineTablePrompt",
  "Data Deviation": "dataDaviationPrompt",
  "Top 5 Positive Attributes Analysis": "positiveAttributesAnalysis",
  "Top 5 Negative Attributes Analysis": "negativeAttributesAnalysis",
  "Top 5 Positive Statements Analysis": "positiveFactsAnalysis",
  "Top 5 Negative Statements Analysis": "negativeFactsAnalysis",
  "Top 5 Brands in Category Analysis": "topBrandsAnalysis",
  "Top 5 Products in Category Analysis": "topProductsAnalysis",
  "Frequently recommended buying criteria/considerations Analysis":
    "buyingAnalysis",
};

export const childPromptNamesForDB = {
  "Brand/Product Overview": "overviewPrompt",
  "Brand/Product Favorability": "favorabilityPrompt",
  "Product Dimensions": "productDimensionsPrompt",
  "Product Reach": "reachPrompt",
  "Brand Category Dimensions": "dimensionsPrompt",
  "Category Leadership": "leadershipPrompt",
  Competition: "competitionPrompt",
  "Brand Attributes": "attributes",
  "Brand Description": "description",
  "Brand/Product Description": "description",
  "Competitive Set": "competetiveSet",
  Sources: "sources",
  "Top 5 Positive and Negative Attributes": "topAttributes",
  "Top 5 Positive and Negative Statements": "topStatements",
  "Competitor Comparison": "competitorComparison",
  "Category Text Input": "textInput",
  "Frequently recommended buying criteria/considerations": "recommendedBuying",
  "Top 5 Brands in Category": "topCategory",
  "Best Brands in Category": "bestCategory",
  "Top 5 Products in Category": "topCategory",
  "Best Products in Category": "bestCategory",
  "Product Description": "productDescription",
  "Product Attributes": "productAttributes",
  Competitors: "competitors",
  "Brand Index Score": "brandIndexPrompt",
  "Brand Index Score for Brand": "brandIndexScore",
  "Brand Index Score for Competitive Set": "competitorBrandIndexScore",
};

export const childMonitoringPromptNamesForDB = {
  "Brand/Product Overview and Favorability": "overviewPrompt",
  "Product Attributes": "productAttributesPrompt",
  "Category Dimensions": "dimensionsPrompt",
  "Category Leadership": "leadershipPrompt",
  Competition: "competitionPrompt",
  Sources: "sources",
  "Brand Index Score": "brandIndexPrompt",
  "Hallucination Detection": "hallucinationPrompt",
  Indexing: "Indexing",
  "Campaign Messages": "campaignPrompt",
  Frequency: "frequencyPrompt",
  "Brand Attributes": "attributes",
  "Brand Description": "description",
  "Competitive Set": "competetiveSet",
  "Top 5 Positive and Negative Attributes": "topAttributes",
  "Top 5 Positive Attributes": "topPositiveAttributes",
  "Top 5 Negative Attributes": "topNegativeAttributes",
  "Top 5 Positive and Negative Statements": "topStatements",
  "Top 5 Positive Statements": "topPositiveStatements",
  "Top 5 Negative Statements": "topNegativeStatements",
  "Competitor Comparison": "competitorComparison",
  "Category Text Input": "textInput",
  "Frequently recommended buying criteria/considerations": "recommendedBuying",
  "Top 5 Brands in Category": "topCategory",
  "Best Brands in Category": "bestCategory",
  "Top 5 Products in Category": "topProductCategory",
  "Best Products in Category": "bestCategory",
  "Product Description": "productDescription",
  Competitors: "competitors",
  "Brand Index Score for Brand": "brandIndexScore",
  "Brand Index Score for Competitive Set": "competitorBrandIndexScore",
};

export const llms = {
  gpt_4_turbo: "GPT-4_Prompts",
  palm2_text: "Palm2_Prompts",
  llama2_70b_chat: "Llama2_Prompts",
  Claude: "Claude_Prompts",
  Gemini: "Gemini_Prompts",
  GPT4: "GPT4_OpenAI_Prompts",
  "GPT-4_Web": "GPT4_Web_Prompts",
};

export const monitoringPromptHeaders = [
  {
    title: "Global Start and End Prompts",
    subItems: [
      { title: "Start prompt with", subChecks: [] },
      { title: "End prompt with", subChecks: [] },
    ],
  },
  {
    title: "Custom Key Prompts",
    subItems: [
      { title: "Key Prompt Prefix", subChecks: [] },
      { title: "Key Prompt Suffix", subChecks: [] },
      { title: "Key Prompt Sources", subChecks: [] },
    ],
  },
  {
    title: "Custom Prompts for Company/Brand/Products",
    subItems: [
      {
        title: "Brand/Product Overview and Favorability",
        subChecks: [
          "Brand Description",
          "Product Description",
          "Top 5 Positive Attributes",
          "Top 5 Negative Attributes",
          "Top 5 Positive Statements",
          "Top 5 Negative Statements",
        ],
      },

      {
        title: "Category Leadership",
        subChecks: ["Top 5 Brands in Category", "Top 5 Products in Category"],
      },
      {
        title: "Category Dimensions",
        subChecks: ["Frequently recommended buying criteria/considerations"],
      },

      {
        title: "Competition",
        subChecks: [
          "Competitive Set",
          "Top 5 Positive Attributes",
          "Top 5 Negative Attributes",
        ],
      },

      {
        title: "Sources",
        subChecks: [
          "Brand Description",
          "Product Description",
          "Top 5 Positive Attributes",
          "Top 5 Negative Attributes",
          "Top 5 Brands in Category",
          "Top 5 Products in Category",
          "Frequently recommended buying criteria/considerations",
          "Competitive Set",
        ],
      },
      {
        title: "Brand Index Score",
        subChecks: [
          "Brand Index Score for Brand",
          "Brand Index Score for Competitive Set",
        ],
      },

      {
        title: "Hallucination Detection",
        subChecks: [],
      },
      {
        title: "Indexing",
        subChecks: [],
      },
      {
        title: "Product Attributes",
        subChecks: [],
      },
      {
        title: "Campaign Messages",
        subChecks: [],
      },
      {
        title: "Frequency",
        subChecks: [],
      },
    ],
  },
  {
    title: "Custom Prompts for Attribute analysis",
    subItems: [
      { title: "Top 5 Positive Attributes Analysis", subChecks: [] },
      { title: "Top 5 Negative Attributes Analysis", subChecks: [] },
      { title: "Top 5 Positive Statements Analysis", subChecks: [] },
      { title: "Top 5 Negative Statements Analysis", subChecks: [] },
      { title: "Top 5 Brands in Category Analysis", subChecks: [] },
      { title: "Top 5 Products in Category Analysis", subChecks: [] },
      {
        title: "Frequently recommended buying criteria/considerations Analysis",
        subChecks: [],
      },
    ],
  },
  {
    title: "Custom Prompts For Unique Data Synthesization & Comparison",
    subItems: [
      { title: "Unique Data Synthesization", subChecks: [] },
      { title: "Baseline Table", subChecks: [] },
      { title: "Monitoring Table", subChecks: [] },
      { title: "Data Comparison", subChecks: [] },
      { title: "Data Deviation", subChecks: [] },
    ],
  },
];

export const dashboardPromptBackup = {
  averageRankSignalStrength:
    "Objective: Extract and consolidate the 'Top 5 Products’ or 'Top 5 Brands’ sections from datasets, calculate the average rank percentile accurately, and present precise results.     1. Data Extraction and Consolidation:         ◦ Extract the 'Top 5 Products’ or 'Top 5 Brands’ sections from the provided datasets.     2. Average Rank Calculation:         ◦ Calculate the average rank percentile as follows:             ▪ Find the position (number) of each brand/product name within each individual dataset. Ensure that variations like 'Apple.com' or 'Apple Inc.' are treated as the same entity, 'Apple', so keep unique name for both in the final result and remove other.             ▪ Assign percentiles to positions: 1st position is 100%, 2nd position is 80%, 3rd position is 60%, 4th position is 40%, and 5th position is 20%. Absent brands/products are assigned 0%.             ▪ Sum up all these percentiles.             ▪ Divide this sum by the total number of datasets.             ▪ Example calculation: Consider we have 4 datasets. If a brand/product name is present in the 1st position in one dataset, in the 2nd position in another dataset, and absent in the remaining datasets, the calculation would be ((100+80+0+0)/4 = 45%).     3. Presentation of Results:         ◦ Top brand/product Average Rank:             ▪ Present results in bullet point format:                 • Brand/Product Name 1 (Rank%)                 • Brand/Product Name 2 (Rank%)                 • Brand/Product Name 3 (Rank%)                 • etc.         ◦ Ensure accuracy and stability in calculations to maintain data integrity.",
  averageRankSourcesStrength:
    "Objective: Extracting and Consolidating Sources Sections, calculate source names strength, and present accurate results.  1. Data Extraction and Consolidation:         ◦ Extract 'Sources' sections from the provided datasets.     2. Source Name Strength Calculation:         ◦ Calculate the strength of each source name:             ▪ If a source name is present in all datasets, assign it 100% strength.             ▪ For source name present in some datasets, calculate strength as follows: (Number of datasets with the source name / Total datasets) * 100.     3. Presentation of Results:         ◦ Sources:             ▪ Present results in bullet point format.                 • source 1 (Strength)                 • source 2 (Strength)                 • source 3 (Strength)                 • etc....(keep only single unique name for the source in final presentation. e.g. if source ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  buyingCriteriaSignalStrength:
    "Objective: Extract 'Purchase/Buying criteria dimensions'  sections from provided datasets, calculate attribute strength, and present accurate results.     1. Data Extraction and Consolidation:         ◦ Extract  'Purchase/Buying criteria dimensions'  sections from the provided datasets.     2. Attribute Strength Calculation:         ◦ For attributes present in all datasets, assign 100% strength.         ◦ For attributes with similar meaning but different wording/phrases, consider them as one and calculate strength accordingly. ▪ Example:  consider we have 2 datasets, If \"camera quality\" is present in one dataset and \"camera performence\" is present in another dataset, consider them as one attribute with 100% strength since they have different phrases but convey the same meaning and present in both datasets. (ensuring expressiveness though. For instance, if one key is 'good camera' and another is  'camera should be value for money,' they should be considered different attributes).....         ◦ For attributes present in some datasets only, calculate strength as (number of datasets with attribute / total datasets) * 100.     3. Presentation of Results:         ◦'Purchase/Buying criteria dimensions'  :             ▪ Present results in bullet point format.                 • Attribute 1 (Strength)                 • Attribute 2 (Strength)                 • Attribute 3 (Strength)                 • etc....keep only single unique name for the attribute in final presentation. e.g. if attribute ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  buyingCriteriaSourcesSignalStrength:
    "Objective: Extracting and Consolidating Sources Sections, calculate source names strength, and present accurate results.  1. Data Extraction and Consolidation:         ◦ Extract 'Sources' sections from the provided datasets.     2. Source Name Strength Calculation:         ◦ Calculate the strength of each source name:             ▪ If a source name is present in all datasets, assign it 100% strength.             ▪ For source name present in some datasets, calculate strength as follows: (Number of datasets with the source name / Total datasets) * 100.     3. Presentation of Results:         ◦ Sources:             ▪ Present results in bullet point format.                 • source 1 (Strength)                 • source 2 (Strength)                 • source 3 (Strength)                 • etc....(keep only single unique name for the source in final presentation. e.g. if source ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  categoryPerformence: {
    topBrandsInCategory:
      "Provide the 'Top 5 Brands' for the provided category. Present the brand names in a bullet list format under the header 'Top 5 Brands .' Additionally, provide the sources (source names in format like amazon.com etc) from which these brand names were retrieved. Present the sources in a bullet list format under the header 'Sources'. Ensure that the information provided is strictly in bullet point sentences, without any extra bullet child points, child headers, etc.",
    topProductsInCategory:
      "Provide the 'Top 5 Products' for the provided category. Present the Product names in a bullet list format under the header 'Top 5 Products .' Additionally, provide the sources (source names in format like amazon.com etc) from which these Product names were retrieved. Present the sources in a bullet list format under the header 'Sources'. Ensure that the information provided is strictly in bullet point sentences, without any extra bullet child points, child headers, etc.",
  },
  categoryPerformencePrefix:
    "Follow proper indication given below, don't add any extra info, headers etc.",
  categoryPrompt: {
    buyingCriteriaDimensions:
      "Extract and List the top 5 decision criteria or considerations for provided category (please provide attributes name optimized format in 1-2 words (with less character) max.), that are important for end customer (no more than 5) Please provide responses in bullet point form and in order of importance with the most important first. Present it under the header 'Purchase/Buying criteria dimensions.'., Additionally, provide the sources (source names like Amazon.com, etc, In max 1-2 words and should be genuine.)from which these attributes were retrieved. Present the sources in a bullet list format under the header 'Sources'.Ensure that the information provided is strictly in bullet point sentences, without any extra bullet child points, child headers, etc.",
  },
  categoryPromptPrefix:
    "Follow proper indication given below, don't add any extra info, headers etc.",
  globalEndPrompt:
    "Do not send data related to words like 'Sure' and 'I hope'. Be brief. ",
  globalStartPrompt: "I am an end consumer looking for information on a",
  negativeAttributeSourcesSignal:
    "Objective: Extracting and Consolidating Sources Sections, calculate source names strength, and present accurate results.  1. Data Extraction and Consolidation:         ◦ Extract 'Sources' sections from the provided datasets.     2. Source Name Strength Calculation:         ◦ Calculate the strength of each source name:             ▪ If a source name is present in all datasets, assign it 100% strength.             ▪ For source name present in some datasets, calculate strength as follows: (Number of datasets with the source name / Total datasets) * 100.     3. Presentation of Results:         ◦ Sources:             ▪ Present results in bullet point format.                 • source 1 (Strength)                 • source 2 (Strength)                 • source 3 (Strength)                 • etc....(keep only single unique name for the source in final presentation. e.g. if source ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  negativeAttributesSignal:
    "Objective: Extract 'Top 5 negative attributes'  sections from provided datasets, calculate attribute strength, and present accurate results.     1. Data Extraction and Consolidation:         ◦ Extract  'Top 5 negative attributes'  sections from the provided datasets.     2. Attribute Strength Calculation:         ◦ For attributes present in all datasets, assign 100% strength.         ◦ For attributes with similar meaning but different wording/phrases, consider them as one and calculate strength accordingly. ▪ Example:  consider we have 2 datasets, If \"poor quality camera\" is present in one dataset and \"bad quality camera\" is present in another dataset, consider them as one attribute with 100% strength since they have different phrases but convey the same meaning and present in both datasets. (ensuring expressiveness though. For instance, if one key is 'camera is good,' and another is  'camera is value for money,' they should be considered different attributes).....         ◦ For attributes present in some datasets only, calculate strength as (number of datasets with attribute / total datasets) * 100.     3. Presentation of Results:         ◦'Top negative attributes' :             ▪ Present results in bullet point format.                 • Attribute 1 (Strength)                 • Attribute 2 (Strength)                 • Attribute 3 (Strength)                 • etc...keep only single unique name for the attribute in final presentation. e.g. if attribute ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  negativeFactsSignal:
    "Objective: Extract 'Top 5 negative facts' sections from provided datasets, calculate fact strength, and present accurate results. 1. Data Extraction and Consolidation: ◦ Extract 'Top 5 negative facts' sections from the provided datasets. 2. Fact Strength Calculation: ◦ For facts present in all datasets, assign 100% strength. ◦ For facts with similar meaning but different wording/phrases, consider them as one and calculate strength accordingly. ▪ Example: consider we have 2 datasets, If \"poor quality camera\" is present in one dataset and \"bad quality camera\" is present in another dataset, consider them as one fact with 100% strength since they have different phrases but convey the same meaning and present in both datasets. (ensuring expressiveness though. For instance, if one key is 'camera is good,' and another is 'camera is value for money,' they should be considered different facts)..... ◦ For facts present in some datasets only, calculate strength as (number of datasets with fact / total datasets) * 100. 3. Presentation of Results: ◦'Top negative facts' : ▪ Present results in bullet point format. • fact 1 (Strength) • fact 2 (Strength) • fact 3 (Strength) • etc...keep only single unique name for the fact in final presentation. e.g. if fact ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  overviewPrompt: {
    topNegativeAttributes:
      "Extract the 'Top 5 negative attributes' about the provided brand/product/category.(please provide attributes name optimized format in 1-2 words (with less character) max.) Present the attributes in a bullet list format under the header 'Top 5 negative attributes'. Additionally, provide the sources (source names like Amazon.com, etc, In max 1-2 words and should be genuine.) from which these attributes were retrieved. Present the sources in a bullet list format under the header 'Sources'. Ensure that the information provided is strictly in bullet point sentences, without any extra bullet child points, child headers, etc.",
    topPositiveAttributes:
      "Extract the 'Top 5 positive attributes' about the provided brand/product/category. (Please condense the attributes name to 1-2 words (max characters limit) for space efficiency). Present the attributes in a bullet list format under the header 'Top 5 positive attributes.' Additionally, provide the sources (source names like Amazon.com, etc, In max 1-2 words and should be genuine.) utilized in your response. Present the sources in a bullet list format under the header 'Sources'. Ensure that the information provided is strictly in bullet point sentences, without any extra bullet child points, child headers, etc.",
    topNegativeFacts:
      "Extract the 'Top 5 negative facts' about the provided brand/product/category.(please provide facts name optimized format in 1-2 words (with less character) max.) Present the facts in a bullet list format under the header 'Top 5 negative facts'. Additionally, provide the sources (source names like Amazon.com, etc, In max 1-2 words and should be genuine.) from which these facts were retrieved. Present the sources in a bullet list format under the header 'Sources'. Ensure that the information provided is strictly in bullet point sentences, without any extra bullet child points, child headers, etc.",
    topPositiveFacts:
      "Extract the 'Top 5 positive facts' about the provided brand/product/category. (Please condense the facts name to 1-2 words (max characters limit) for space efficiency). Present the facts in a bullet list format under the header 'Top 5 positive facts.' Additionally, provide the sources (source names like Amazon.com, etc, In max 1-2 words and should be genuine.) utilized in your response. Present the sources in a bullet list format under the header 'Sources'. Ensure that the information provided is strictly in bullet point sentences, without any extra bullet child points, child headers, etc.",
  },
  overviewPromptPrefix:
    "Follow proper indication given below, don't add any extra info, headers etc.",
  positiveAttributeSourcesSignal:
    "Objective: Extracting and Consolidating Sources Sections, calculate source names strength, and present accurate results.  1. Data Extraction and Consolidation:         ◦ Extract 'Sources' sections from the provided datasets.     2. Source Name Strength Calculation:         ◦ Calculate the strength of each source name:             ▪ If a source name is present in all datasets, assign it 100% strength.             ▪ For source name present in some datasets, calculate strength as follows: (Number of datasets with the source name / Total datasets) * 100.     3. Presentation of Results:         ◦ Sources:             ▪ Present results in bullet point format.                 • source 1 (Strength)                 • source 2 (Strength)                 • source 3 (Strength)                 • etc....(keep only single unique name for the source in final presentation. e.g. if source ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  positiveAttributesSignal:
    "Objective: Extract 'Top 5 positive attributes' sections from provided datasets, calculate attribute strength, and present accurate results.     1. Data Extraction and Consolidation:         ◦ Extract  'Top 5 positive attributes' sections from the provided datasets.     2. Attribute Strength Calculation:         ◦ For attributes present in all datasets, assign 100% strength.         ◦ For attributes with similar meaning but different wording/phrases, consider them as one and calculate strength accordingly. ▪ Example:  consider we have 2 datasets, If \"high quality camera\" is present in one dataset and \"better quality camera\" is present in another dataset, consider them as one attribute with 100% strength since they have different phrases but convey the same meaning and present in both datasets. (ensuring expressiveness though. For instance, if one key is 'camera is good,' and another is  'camera is value for money,' they should be considered different attributes).....         ◦ For attributes present in some datasets only, calculate strength as (number of datasets with attribute / total datasets) * 100.     3. Presentation of Results:         ◦ Top Positive Attributes:             ▪ Present results in bullet point format.                 • Attribute 1 (Strength)                 • Attribute 2 (Strength)                 • Attribute 3 (Strength)                 • etc....(keep only single unique name for the attribute in final presentation. e.g. if attribute ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  positiveFactsSignal:
    "Objective: Extract 'Top 5 positive facts' sections from provided datasets, calculate fact strength, and present accurate results. 1. Data Extraction and Consolidation: ◦ Extract 'Top 5 positive facts' sections from the provided datasets. 2. Fact Strength Calculation: ◦ For facts present in all datasets, assign 100% strength. ◦ For facts with similar meaning but different wording/phrases, consider them as one and calculate strength accordingly. ▪ Example: consider we have 2 datasets, If \"poor quality camera\" is present in one dataset and \"bad quality camera\" is present in another dataset, consider them as one fact with 100% strength since they have different phrases but convey the same meaning and present in both datasets. (ensuring expressiveness though. For instance, if one key is 'camera is good,' and another is 'camera is value for money,' they should be considered different facts)..... ◦ For facts present in some datasets only, calculate strength as (number of datasets with fact / total datasets) * 100. 3. Presentation of Results: ◦'Top positive facts' : ▪ Present results in bullet point format. • fact 1 (Strength) • fact 2 (Strength) • fact 3 (Strength) • etc...keep only single unique name for the fact in final presentation. e.g. if fact ‘abc’ and ‘xyz’ has similar meaning then keep only ‘abc’ and not the ‘abc’/’xyz’)...... Ensure accuracy and stability in calculations to maintain data integrity.",
  synthesizationPrompt:
    "Generate and provide a JSON object by extracting necessary information from the given dataset, as directed below: The dataset includes various attributes/sources/products/brands name with associated strengths/ranks represented as percentages/plain numbers (in brackets) alongside them. Your task is to extract only this specific information and format it into a JSON object. Each key in the object should represent an attribute/source/brand/product name label, and its corresponding value should be the strength/rank percentage/plain number as a number without any symbols, text, or units.  Additionally, you need to prioritize searching for the necessary information in the 'Presentation of results'/'result presentation' or similar section in the dataset. Extract from this section if available. If such a section is not present, conduct a comprehensive search and extract necessary information as required........please provide just finished json without any additional analysis information etc.",
};

export const dashboardPromptNamesForDB = {
  "Start prompt with": "globalStartPrompt",
  "End prompt with": "globalEndPrompt",
  "Brand/Product Overview": "overviewPromptPrefix",
  "Category Dimensions": "categoryPromptPrefix",
  "Category Performance": "categoryPerformencePrefix",
  "Top 5 positive attributes signal strength": "positiveAttributesSignal",
  "Top 5 positive facts signal strength": "positiveFactsSignal",
  "Top 5 positive attributes sources signal strength":
    "positiveAttributeSourcesSignal",
  "Top 5 negative attributes signal strength": "negativeAttributesSignal",
  "Top 5 negative facts signal strength": "negativeFactsSignal",
  "Top 5 negative attributes sources signal strength":
    "negativeAttributeSourcesSignal",
  "Purchase/Buying criteria Dimensions signal strength":
    "buyingCriteriaSignalStrength",
  "Purchase/Buying criteria Dimensions sources signal strength":
    "buyingCriteriaSourcesSignalStrength",
  "Brands/Products average rank signal strength": "averageRankSignalStrength",
  "Brands/Products average rank sources signal strength":
    "averageRankSourcesStrength",
  "Unique Data Synthesization": "synthesizationPrompt",
};

export const childDashboardPromptNamesForDB = {
  "Brand/Product Overview": "overviewPrompt",
  "Category Dimensions": "categoryPrompt",
  "Top 5 Positive Attributes": "topPositiveAttributes",
  "Top 5 Negative Attributes": "topNegativeAttributes",
  "Top 5 Positive Facts": "topPositiveFacts",
  "Top 5 Negative Facts": "topNegativeFacts",
  "Purchase/Buying criteria Dimensions": "buyingCriteriaDimensions",
  "Category Performance": "categoryPerformence",
  "Top 5 Brands in Category": "topBrandsInCategory",
  "Top 5 Products in Category": "topProductsInCategory",
};

export const dashboardPromptHeaders = [
  {
    title: "Global Start and End Prompts",
    subItems: [
      { title: "Start prompt with", subChecks: [] },
      { title: "End prompt with", subChecks: [] },
    ],
  },

  {
    title: "Custom Prompts for Company/Brand/Products",
    subItems: [
      {
        title: "Brand/Product Overview",
        subChecks: [
          "Top 5 Positive Attributes",
          "Top 5 Negative Attributes",
          "Top 5 Positive Facts",
          "Top 5 Negative Facts",
        ],
      },

      {
        title: "Category Dimensions",
        subChecks: ["Purchase/Buying criteria Dimensions"],
      },
      {
        title: "Category Performance",
        subChecks: ["Top 5 Brands in Category", "Top 5 Products in Category"],
      },
    ],
  },

  {
    title:
      "Custom Prompts for Signal strength calculations of metrics and their sources",
    subItems: [
      { title: "Top 5 positive attributes signal strength", subChecks: [] },
      { title: "Top 5 positive facts signal strength", subChecks: [] },
      {
        title: "Top 5 positive attributes sources signal strength",
        subChecks: [],
      },
      { title: "Top 5 negative attributes signal strength", subChecks: [] },
      { title: "Top 5 negative facts signal strength", subChecks: [] },
      {
        title: "Top 5 negative attributes sources signal strength",
        subChecks: [],
      },
      {
        title: "Purchase/Buying criteria Dimensions signal strength",
        subChecks: [],
      },
      {
        title: "Purchase/Buying criteria Dimensions sources signal strength",
        subChecks: [],
      },
      {
        title: "Brands/Products average rank signal strength",
        subChecks: [],
      },
      {
        title: "Brands/Products average rank sources signal strength",
        subChecks: [],
      },
    ],
  },

  {
    title: "Custom Prompts For Unique Data Synthesization",
    subItems: [{ title: "Unique Data Synthesization", subChecks: [] }],
  },
];

export const brandIndexPrompts = `
Product: [input]
Give me product description, product reviews, positive and negative attributes of product, customer feedback and social mentions.

I need a plain text paragraph. Do not include any markdown, bullet points, or other formatting elements. Just provide the text as if it were written in a simple text editor.
`;

export const brandIndexRankPrompt = `
I am an end consumer looking for information on a [input]. Provide the list of 'Top [product_count] Products' for the provided category.
Data schema should be like this: 

{
  products: [
    PRODUCT_NAME
  ]
}

Use single quote for referencing inches in product name. Make sure to use double quotes around values of product name.
Please provide a JSON response without including any additional data. The response should strictly adhere to the JSON format.
`;

export const attributeAggregationPrompt = `
Positive Attributes - 
[Positives]

Negative Attributes
[Negatives]

Given these two array, identify the string array having same sort of meaning in minimum 4 groups.

Data schema should be like this: 

{
  "positive_attributes": [
      { 
          "groupName: "value", (string),
          "attributes: "value" (array)
      }
  ],
  "negative_attributes": [
      { 
          "groupName: "value", (string),
          "attributes: "value" (array)
      }
  ]
}

Please provide a JSON response without including any additional data. The response should strictly adhere to the JSON format.
Do not send data related to words like 'Sure' and 'I hope'. Be brief. Provide response in JSON format only
`;

export const SOURCES_CONSOLIDATION_PROMPT = `Please follow below steps and provide accurate result as directed..
Step 1 - Identify Variations:
Review the main array containing child arrays of brand/product information source names. Identify any variations in wording/phrasing across 
source names that convey the same meaning. Record these variations.
Step 2 - Assign Unique Names:
From the identified variations, select a single, descriptive name for each. 
Ensure that the chosen name is unique and represents the source name accurately. 
Choose the most commonly understood name. For example, if variations include "Amazon," 
"Amazon.com," and "Amazon website," opt for a representative name like 
"Amazon.com" to replace all instances.
Step 3 - Modify the Array:
For each item/source name in the array, replace any variation with its single, 
unique name determined in Step 2.
Step 4 - Provide Modified Array:
Submit the final modified array, where each source name is represented by its unique name.`;

export const ATTRIBUTES_OPTIMIZATION_PROMPT = `Please follow below steps and provide accurate result as directed..
 Step 1 - Identify Variations:
 Review the main array containing child arrays of brand/product attributes. Identify any variations in wording/phrasing across 
 attributes that convey the same meaning. Record these variations.
 Step 2 - Assign Unique Names:
 From the identified variations, select a single, descriptive name for each. 
 Ensure that the chosen name is unique and represents the attribute accurately. 
 Choose the most commonly understood name. For example, if variations include "camera is good," 
 "excellent camera," and "superior quality camera," opt for a representative name like 
 "excellent camera" to replace all instances.
 Step 3 - Modify the Array:
 For each item/attribute in the array, replace any variation with its single, 
 unique name determined in Step 2.
 Step 4 - Provide Modified Array:
 Submit the final modified array, where each attribute is represented by its unique name.`;

export const SOURCES_PROMPT = `Please follow below steps and provide accurate data:\nstep  
1 : Extract source names from markdown datasets under headers 'Sources’ , 
and generate a child Array for each dataset. \nstep  
2 : push all above arrays (would act as a child arrays) into main array.\nstep  
3 : provide a main array as a final output without any additional information..`;

export const DATA_COMPARISON_PROMPT = `Please follow below steps and provide accurate result as directed....
You have two sets of objects containing attributes such as source, brand, product names or reviews
 as keys and attribute values as corresponding values....Also, please note., second set of objects contain only one object and first set of objects contain one or 
 more than one objects.
 Step 1 - compare the keys of the second set object with the keys of the
   every objects in first set. If a key in the second object set conveys the same meaning but
   has different wording or phrasing compared to a key in the any of the object from first objects set, 
   you should replace it with the corresponding key from the first objects set. 
  Step 2 - Provide modified second JSON object with the replaced keys`;

export const BRAND_INPUT_VALIDATION_PROMPT = `
  Act as a product and brand knowledge expert. Analyze the following input: "[input]" as a [type] name.
  
  Task:
  1. Determine if it's a valid [type] name (considering brands and products).
  2. Check for possible misspellings, partial matches, or similar [type] names.
  3. Provide the response strictly in the specified JSON format.
  
  Rules for validation:
  - Exact matches must be case-insensitive and match spacing, special characters, and typecase.
  - For invalid or partial matches, include up to 5 most relevant and valid suggestions based on:
    - Similarity to the input.
    - Known [type] names (brands or products).
    - Common patterns in brand/product names.
  - For brands, include parent companies, subsidiaries, and related entities.
  - For products, include both generic and branded names.
  - Use a similarity threshold of at least 75% for suggestions.
  - Ensure that suggestions are clearly related to the input and sorted by relevance.
  
  If the input is invalid or misspelled:
  - The "ans" field must be "No."
  - The "correct_inputs" array should contain valid and relevant suggestions if any exist.
  - Suggestions must be properly capitalized and adhere to real-world naming conventions.
  - Return an empty array if no relevant suggestions are found.
  
  Response format:
  {
      "data": {
          "ans": "Yes/No",
          "correct_inputs": []
      }
  }
  
  Notes:
  - The "ans" field should only be "Yes" for exact matches (case-insensitive but otherwise exact).
  - Variations in typecase, spaces, or special characters result in an invalid entry.
  - The "correct_inputs" field should include valid [type] names closely related to the input, even for partial matches.
  - Provide suggestions that align with the type (brand or product).
  
  Examples:
  1. Input: "Atomic bentchelter" (Type: Product)
     Output: {
         "data": {
             "ans": "No",
             "correct_inputs": ["Atomic Bent Chetler"]
         }
     }
  
  2. Input: "Gogle" (Type: Brand)
     Output: {
         "data": {
             "ans": "No",
             "correct_inputs": ["Google"]
         }
     }
  
  3. Input: "Pizza" (Type: Brand)
     Output: {
         "data": {
             "ans": "No",
             "correct_inputs": ["Pizza Hut", "Pizza Inn"]
         }
     }
  
  4. Input: "Nike" (Type: Brand)
     Output: {
         "data": {
             "ans": "Yes",
             "correct_inputs": []
         }
     }
  
  Guidelines:
  - Analyze the input for exact matches and suggest related [type] names.
  - Use known naming conventions and patterns to identify suggestions.
  - Provide the response strictly in JSON format without any additional text or explanations.
  `;

export const METRICS_EXAMPLE_PROMPT = (brandProduct) => {
  const prompt = {
    indexScore: `[Time frame] for [Brand] is x. [Brand’s] brand index score has been relatively stable/increased
by y/decreased by y over the time period. Drivers of [Brand’s] score are a strong/weak Reach score and
a strong/weak sentiment score. [Brand]’s score of X puts it in Z place for the [category] category.`,
    favorability: `[Brand]’s Brand Sentiment/Favorability at [time period] with in stable, increasing, or decreasing over the time period. Key
drivers of the favoability/sentiment score are Large Language models positive description of [Brand]'s x, y, and z, however negative
factors of z, h are impacting [Brand] favoability/sentiment score.`,
    rank: `[Brand]’s Brand Reach for [category name ] category is X increasing/decreasing by x over time period. [Brand]
currently places y among competitors with P, Q, R have stronger/weaker Brand Reach Scores p,q,r respectively.`,
    positiveAttributes: `Top 5 positive attribute-category in terms of signal strength in [current time frame] are a, b, c, d, e. X
and Y are trending up over time with Z decreasing. Other signicant positive attribures trending up over
time are A, B, and C. The major characteristic making up the top 3 atrributes are:
 Attribute 1:
o Characteristic 1, Characteristic 2
 Attribute 2:
o Characteristic
 Attribute 3: `,
    negativeAttributes: `Top 5 negative attribute-category in terms of signal strength in [current time frame] are a, b, c, d, e. X
and Y are trending up over time with Z decreasing. Other signicant negative attribures trending up over
time are A, B, and C. The major characteristic making up the top 3 atrributes are:
 Attribute 1:
o Characteristic 1, Characteristic 2
 Attribute 2:
o Characteristic
 Attribute 3: `,
    brandPillars: `Objective: Provide overall summary with the main subject matter being the 
    ${brandProduct} and how it compares overall across the brand Pillars and 
    specific brand Pillar.
Across the major brand Pillar that LLM will recommend to users, ${brandProduct} excels in 
X,Y,Z brand pillars and is the clear leader in Z brand Pillars. 
Across these A Competitor brand is known for U,V,W Brand pillars.

Objective: identification of who is the leader or close follower, quick comparison to the ${brandProduct}, 
and any numbers that really stand out.

Although ${brandProduct} leads, the A Competitor is close behind with strong scores in brand pillars.  
Other significant call outs are X,Y, Z, performance in X, a clear differentiator for them.


Objective: identify major changes for the ${brandProduct} first and than other brands.
Significant changes for ${brandProduct} over the last X days include X, Y, or Z (only include changes above 2 or more)
Other significant changes include the A Competitor brand increase in score on X, Y, and Z.

Objective:  provide summary statement and also identify what the brand needs to address.

Overall, ${brandProduct} is positioned well against the competition, especially if it 
addresses Brand and Model Availability.`,
    purchaseCriteria: `Objective: Provide overall summary with the main subject matter being the 
    ${brandProduct} and how it compares overall across the Purchase Criterias and 
    specific Purchase Criteria.
Across the major Purchase Criteria that LLM will recommend to users, ${brandProduct} excels in 
X,Y,Z bPurchase Criterias and is the clear leader in Z Purchase Criterias. 
Across these A Competitor brand is known for U,V,W Purchase Criterias.

Objective: identification of who is the leader or close follower, quick comparison to the ${brandProduct}, 
and any numbers that really stand out.

Although ${brandProduct} leads, the A Competitor is close behind with strong scores in Purchase Criterias.  
Other significant call outs are X,Y, Z, performance in X, a clear differentiator for them.


Objective: identify major changes for the ${brandProduct} first and than other brands.
Significant changes for ${brandProduct} over the last X days include X, Y, or Z (only include changes above 2 or more)
Other significant changes include the A Competitor brand increase in score on X, Y, and Z.

Objective:  provide summary statement and also identify what the brand needs to address.

Overall, ${brandProduct} is positioned well against the competition, especially if it 
addresses Brand and Model Availability.`,
  };

  return prompt;
};

export const SUMMARY_PROMPT = `Act as a Business Analyst, provide 
  a concise 50-60 words summary. 
  Ensure the summary does not exceed 60 words, and present it as a 
  single paragraph without using introductory words.`;

export const LLM_OPTIONS = [
  {
    id: 1,
    name: "GPT-4o",
    value: "gpt-4o",
    model: "gpt-4o",
  },
  {
    id: 2,
    name: "Claude-3.5",
    value: "Claude3",
    model: "claude-3-5-sonnet-20240620",
  },
  {
    id: 3,
    name: "Gemini-1.5",
    value: "Gemini",
    model: "gemini-1.5-pro",
  },
  {
    id: 4,
    name: "Llama-3",
    value: "llama3",
    model: "llama-3-70B-Instruct",
  },
  {
    id: 5,
    name: "Perplexity (llama-3.1)",
    value: "Perplexity",
    model: "llama-3.1-sonar-large-128k-online",
  },
  {
    id: 6,
    name: "LLM Web",
    value: "llm-web",
    model: "",
  },
];

export const titleMapping = {
  "Brand Index Score": "Brand Index Score",
  Favorability: "Sentiment",
  Rank: "Reach",
  RBI: "RBI",
  "Brand Pillars": "Brand Pillars",
  "Purchase Criteria": "Purchase Criteria",
};

export const metricExplainations = {
  Reach: `A measure of a brand or product's visibility and ranking in 
  key category prompts, rated on a scale of 0–50`,
  "Brand Index Score": `A composite measure of a brand's reach and sentiment, 
  rated on a scale of 0–100`,
  "Product Index Score": `A composite measure of a product's reach and sentiment, 
  rated on a scale of 0–100`,
  Sentiment: `An assessment of the overall favorability of how a brand or 
  product is described by an LLM, rated on a scale of 0–50`,
  "Brand Pillars": `Eight predefined categories that contribute to a brand's value rated on a scale of 0-10`,
  "Purchase Criteria": `Factors identified by LLMs that influence purchasing decisions, 
  specific to a brand or product category rated on a scale of 0-10.`,
  RBI: `A composite measure of a brand's reach and sentiment, 
  rated on a scale of 0–100`,
  "Product Dimensions": `Attributes that drive product value. Two are 
  predefined—Reputation and Value—while additional dimensions are generated by 
  the LLM rated on a scale of 0-10`,
  Quality: `Emphasises excellence in craftsmanship, consistency, and attention to detail. 
  It highlights premium materials, rigorous testing standards, and a commitment 
  to customer satisfaction. Superior quality is ensured across all aspects, 
  from product design to delivery, consistently exceeding expectations.`,
  Trustworthiness: `Focuses on honesty, reliability, and ethical behaviour. 
  It highlights transparency in communications, consistent delivery on promises, 
  and adherence to high ethical standards. The brand prioritizes accountability, 
  data security, and long-term relationships built on mutual respect and customer confidence.`,
  Innovation: `Embodies a pioneering spirit, continuous improvement, and adaptability. 
  It emphasizes creative problem-solving and customer-centric solutions that disrupt 
  markets and create value. The brand introduces 
  novel products or services that meet unmet needs and set new standards.`,
  Value: `Emphasizes the unique benefits and worth a brand offers to customers, stakeholders, 
  and society. It includes both tangible and intangible elements like quality, reputation, 
  customer loyalty, and market position, reflecting the positive impact and differentiation 
  a brand creates.`,
  "Customer Centricity": `Prioritizes customer needs in every decision, focusing on 
  personalized experiences and continuous improvement based on feedback. 
  The brand creates seamless interactions, empowers customers, and builds long-term 
  relationships founded on trust and respect.`,
  "Social Responsibility": `Emphasizes positive community impact and environmental stewardship. 
  It showcases ethical business practices, advocacy for social issues, and a commitment to 
  diversity and inclusion. The brand actively engages in charitable work and sustainable 
  practices for the benefit of society and the environment.`,
  Sustainability: `Focuses on environmental stewardship, ethical practices, 
  and a long-term vision. The brand commits to eco-friendly innovation, 
  transparent accountability, responsible sourcing, and waste reduction, 
  contributing to a sustainable future for both business and the planet.`,
  "Brand Affinity": `Emphasizes building meaningful relationships with customers, 
  fostering loyalty, shared values, and a sense of identity. The brand consistently 
  delivers positive experiences, creating a community where customers feel a sense of 
  belonging and become passionate advocates.`,
};
