import React, { useState, useEffect, useContext } from "react";

import {
  Modal,
  Box,
  Typography,
  Button,
  IconButton,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ActionTypeTab from "./ActionTypeTab";
import {
  createActionMetric,
  fetchActionMetrics,
} from "../../../../redux/actions/metricActions";
import { useSelector, useDispatch } from "react-redux";
import { AuthContext } from "../../../../context/auth-context";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflowY: "auto",
  borderRadius: "8px",
};

const ActionModal = ({ open, handleClose, data, type }) => {
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id;

  const [actionType, setActionType] = useState(type);
  const [actionListItems, setActionListItems] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    setActionType(type);
  }, [type]);

  function transformActionTypeData(inputObjects, mainData, filterType) {
    if (inputObjects) {
      const result = [];

      const keyMapping = {
        llmComp: "llm",
        marketcomp: "market",
        geocomp: "geography",
        personacomp: "persona",
      };

      // Helper function to generate the title
      const generateTitle = (parentKey, key, data, mainData, input) => {
        const parentKeyLabel =
          parentKey.charAt(0).toUpperCase() + parentKey.slice(1); // Capitalize parent key
        if (key === "onlySelf") {
          return `${parentKeyLabel} score is ${data.score}`;
        } else {
          return `${parentKeyLabel} score of ${mainData[keyMapping[key]]} is ${
            input?.self?.score
          } while ${data.targetKey || ""} - ${keyMapping[key]} is ${
            data.score
          }`.trim();
        }
      };

      // Iterate over each parent object
      Object.entries(inputObjects).forEach(([parentKey, input]) => {
        // Apply the filter condition based on filterType
        const isReachFilter = filterType === "Reach";
        const shouldInclude = isReachFilter
          ? parentKey.toLowerCase().includes("reach")
          : !parentKey.toLowerCase().includes("reach");

        if (shouldInclude) {
          // Iterate over the keys in each input object
          Object.keys(input).forEach((key) => {
            const value = input[key];
            if (value && key !== "self") {
              // Exclude null values and 'self' key
              result.push({
                parentKey, // Link to the parent object key
                title: generateTitle(parentKey, key, value, mainData, input),
                sources: value.sources ? value.sources.slice(0, 4) : [], // Limit sources to top 4
              });
            }
          });
        }
      });

      return result;
    }
  }

  const dataMapping = {
    reach: transformActionTypeData(data?.Risk, data, "Reach"),
    risk: transformActionTypeData(data?.Risk, data),
    opp: transformActionTypeData(data?.opp, data),
  };

  const handleActionTypeChange = (key) => {
    setActionType(key);
  };

  const handleCheckboxChange = (obj, checked) => {
    setActionListItems(
      (prevItems) =>
        checked
          ? [...prevItems, obj] // Add item if checked
          : prevItems.filter((item) => item.title !== obj.title) // Remove item if unchecked
    );
  };

  const isChecked = (obj) =>
    actionListItems.some((item) => item.title === obj.title);

  const saveActions = () => {
    const currentActions = [...actionListItems];
    const modifiedActionList = currentActions?.map((obj) => {
      const modifiedObj = {
        ...obj,
        brand: data?.brand,
        persona: data?.persona,
        geography: data?.geography,
        market: data?.market,
        llm: data?.llm,
      };

      return modifiedObj;
    });

    console.log('mod==>', modifiedActionList)
    const payload = {
      action_json: modifiedActionList,
    };
    dispatch(createActionMetric(customerId, payload)).then(() => {
      dispatch(fetchActionMetrics(customerId));
      handleClose();
    });
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={style}>
        <IconButton
          onClick={handleClose}
          sx={{ position: "absolute", top: 8, right: 8, color: "gray" }}
        >
          <CloseIcon />
        </IconButton>

        <ActionTypeTab
          activeTarget={actionType}
          handleChange={handleActionTypeChange}
        />

        <ul style={{ listStyleType: "square", padding: 0 }}>
          {dataMapping?.[actionType]?.map((obj, index) => (
            <li key={index} style={{ marginBottom: "16px" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Typography variant="body1" component="span">
                  {obj?.title}
                </Typography>
                <Checkbox
                  checked={isChecked(obj)}
                  onChange={(e) => handleCheckboxChange(obj, e.target.checked)}
                />
              </div>

              {/* Sources */}
              <Typography variant="h6" component="div" sx={{ mt: 1 }}>
                Sources
              </Typography>
              <ul style={{ paddingLeft: "20px" }}>
                {obj?.sources.map((data, subIndex) => (
                  <li key={subIndex}>
                    <Typography variant="body2">{data}</Typography>
                  </li>
                ))}
              </ul>
              <hr />
            </li>
          ))}
        </ul>

        <Button
          variant="contained"
          color="primary"
          onClick={saveActions}
          sx={{ mt: 2 }}
        >
          Add Actions
        </Button>
      </Box>
    </Modal>
  );
};

export default ActionModal;
