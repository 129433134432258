import {
  FETCH_ACTION_METRICS_REQUEST,
  FETCH_ACTION_METRICS_SUCCESS,
  FETCH_ACTION_METRICS_FAILURE,
  CREATE_ACTION_METRIC_REQUEST,
  CREATE_ACTION_METRIC_SUCCESS,
  CREATE_ACTION_METRIC_FAILURE,
  UPDATE_ACTION_METRIC_REQUEST,
  UPDATE_ACTION_METRIC_SUCCESS,
  UPDATE_ACTION_METRIC_FAILURE,
  DELETE_ACTION_METRIC_REQUEST,
  DELETE_ACTION_METRIC_SUCCESS,
  DELETE_ACTION_METRIC_FAILURE,
} from "../constants/actionTypes";

const initialState = {
  loading: false,
  data: [],
  error: null,
  deleteLoading: false,
};

const actionMetricsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_ACTION_METRICS_REQUEST:
    case CREATE_ACTION_METRIC_REQUEST:
    case UPDATE_ACTION_METRIC_REQUEST:
      // case DELETE_ACTION_METRIC_REQUEST:
      return {
        ...state,
        loading: true,
        // deleteLoading: true,
      };
    case DELETE_ACTION_METRIC_REQUEST:
      return {
        ...state,
        // loading: true,
        deleteLoading: true,
      };
    case FETCH_ACTION_METRICS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    case CREATE_ACTION_METRIC_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: [...state.data, action.payload],
      };
    case UPDATE_ACTION_METRIC_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: state.data.map((brand) =>
        //   brand.id === action.payload.id ? action.payload : brand
        // ),
      };
    case DELETE_ACTION_METRIC_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        // data: state.data.data.filter(brand => brand.id !== action.payload),
      };
    case FETCH_ACTION_METRICS_FAILURE:
    case CREATE_ACTION_METRIC_FAILURE:
    case UPDATE_ACTION_METRIC_FAILURE:
    case DELETE_ACTION_METRIC_FAILURE:
      return {
        ...state,
        loading: false,
        deleteLoading: false,
        // error: action.error,
      };
    default:
      return state;
  }
};

export default actionMetricsReducer;
