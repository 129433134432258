import { useEffect, useContext, useState, useRef } from "react";
import {
  Skeleton,
  TableHead,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";

import MUICard from "./../../../Component/UI/MUICard/MUICard";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "./../../../Component/UI/Table/Table";
import { DiscoveryContext } from "../discovery-context";
import { extractDomain } from "../../../utils/helpers";
import classes from "./CategoryDimensions.module.css";
import ErrorView from "../../../Component/ErrorView/ErrorView";
import api from "../../../BrandOnBoarding/api";
import MUIToolTip from "../../../Component/UI/MUIToolTip/MUIToolTip";

const TABLE_HEADERS = [
  {
    id: "criteria",
    name: "Purchase Criteria",
  },
];

export default function CategoryDimensions({ category }) {
  const [error, setError] = useState();
  const discoveryCtx = useContext(DiscoveryContext);

  const abortControllerRef = useRef(null);

  async function getCriterias() {
    setError(null);
    const competitors = discoveryCtx.competitors.map((c) => c.name);

    const payload = {
      llm: discoveryCtx.llm,
      category: category,
      brand: competitors.slice(0, 1)[0],
      competitors: competitors.slice(1, 4),
      persona: "General Profile",
      location: "",
      market: discoveryCtx.market,
    };

    abortControllerRef.current = new AbortController();

    try {
      if(discoveryCtx.llm === "Perplexity") {
        await new Promise(res=> setTimeout(()=> res(''), 120000))
      }
      const response = await api.post(
        "/metrics/v3/category-dimensions",
        payload,
        { signal: abortControllerRef.current.signal }
      );

      discoveryCtx.updateCategoryDimensions({
        data: response.data.data,
        summary: response.data.summary,
      });

      const sources = [
        ...Object.keys(response.data.sources.category),
        ...Object.keys(response.data.sources.dimension),
      ];
      discoveryCtx.updateSources(sources);
    } catch (error) {
      console.log("Error: ", error.message);
      setError(error.message);
    }
  }

  useEffect(()=>{
    if(discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  },[discoveryCtx.abort_launch])

  useEffect(() => {
    if (
      discoveryCtx?.category_dimensions &&
      Object.keys(discoveryCtx?.category_dimensions).length > 0
    ) {
      return;
    }

    getCriterias();

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };

  }, []);

  function LoadingSkeletion() {
    return (
      <div className="p-3">
        <Skeleton variant="rectangular" height={30} />
        {Array(10)
          .fill(0)
          .map((_, index) => (
            <Skeleton
              variant="rectangular"
              width={index % 2 === 0 ? "70%" : "97%"}
              key={index}
              className="my-3"
              height={20}
            />
          ))}
      </div>
    );
  }

  function renderHeaders(criterias) {
    const headers = [
      ...TABLE_HEADERS,
      ...discoveryCtx.competitors.map((item) => ({
        id: item.name,
        name: item.name,
      })),
    ];

    return (
      <TableHead className={classes.table_head}>
        <TableRow>
          {headers.map((header, index) => {
            let ctxCompetitor = null
            if(index !== 0) {
              ctxCompetitor = discoveryCtx.competitors[index-1];
            }

            let domain = "";
            if (ctxCompetitor && ctxCompetitor?.domain) {
              domain = extractDomain(ctxCompetitor?.domain);
            }

            return (
              <TableCell key={index} className={classes.table_head_cell}>
                <div className={classes.table_head_content}>
                  {index != 0 && (
                    <img
                      data-html2canvas-ignore
                      className={classes.table_head_logo}
                      src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div
                    className={
                      index === 0
                        ? classes.table_head_text_comp
                        : classes.table_head_text
                    }
                  >
                    {header.name}
                  </div>
                </div>
              </TableCell>
            );
          })}
        </TableRow>
      </TableHead>
    );
  }

  function renderRows(rowItem, tableIndex) {
    return (
      <StyledTableRow key={tableIndex}>
        <StyledTableCell className={classes.attribute_headings}>
          {rowItem.name}
        </StyledTableCell>
        {rowItem.brands.map((item, index) => {
          let color = "#dc3545";
          let rating = "low";
          if (item.rating >= 7) {
            rating = "fair";
            color = "#2196F3";
          }
          if (item.rating >= 9) {
            color = "#198754";
            rating = "high"
          }
          let sources = [];
          if(item?.sources) {
            sources = item?.sources.map(source=> ({ source: source, snippet: ""}));
          }
          
          return (
            <MUIToolTip
              additionalText={`${item.name} received a rating of ${item.rating} out of 10, a ${rating} score in ${rowItem.name}`}
              showSources
              disableAction
              llm={discoveryCtx.llm}
              llmSources={sources}
              key={index}
              title={item.explanation}
              arrow
              actionText="Add To Actions"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: "offset",
                      options: {
                        offset: [0, -18],
                      },
                    },
                  ],
                },
              }}
            >
              <StyledTableCell
                style={{ color }}
                className={classes.attribute_score}
              >
                {item.rating}
              </StyledTableCell>
            </MUIToolTip>
          );
        })}
      </StyledTableRow>
    );
  }

  let loading = true;
  let criterias = {};
  if (
    discoveryCtx?.category_dimensions &&
    Object.keys(discoveryCtx?.category_dimensions).length > 0
  ) {
    criterias = discoveryCtx?.category_dimensions;
    loading = false;
  }

  return (
    <MUICard variant="elevated" elevation={0} className={classes.card}>
      {!loading && !error && criterias?.data?.length > 0 && (
        <Table
          elevation={0}
          data={criterias?.data}
          renderHeaders={() => renderHeaders(criterias?.data)}
          render={renderRows}
        />
      )}
      {error && (
        <div className={classes.error}>
          <ErrorView
            title={"Error fetching category dimensions"}
            message={error}
            retry
            onRetry={() => getCriterias()}
          />
        </div>
      )}
      {loading && !error && <LoadingSkeletion />}
    </MUICard>
  );
}
