import { useState, useContext, useEffect } from "react";

import api from "../../../../BrandOnBoarding/api";
import { AuthContext } from "../../../../context/auth-context";
import Modal from "./../../../../Component/Modal/Modal";
import FilterItem from "./FilterItem";
import LoadingSkeleton from "../../../PromptEngine/History/LoadingSkeleton";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import ErrorView from "../../../../Component/ErrorView/ErrorView";

const ITEMS_PER_PAGE = 10;

export default function FilterList({ open, onClose, onSelect, type = "brand" }) {
  const [searchFilters, setSearchFilters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [offset, setOffset] = useState(0);
  const [hasMoreData, setHasMoreData] = useState(true);

  const { user } = useContext(AuthContext);

  async function getSearchFilters() {
    try {
      setError(null);
      setLoading(true);

      let url = `users/search-filter/${user.id}`;
      url += `?offset=${offset}&limit=${ITEMS_PER_PAGE}&type=${type}`;

      const response = await api.get(url);
      if (response?.data?.data) {
        setOffset((prevOffset) => prevOffset + ITEMS_PER_PAGE);
        setSearchFilters((prevSearchFilters) => {
          return [...prevSearchFilters, ...response.data.data];
        });
        if (response?.data?.data.length === 0) {
          setHasMoreData(false);
        }
      } else {
        setError({ type: "load", message: "Failed to load serach filters" });
      }
    } catch (error) {
      setError({ type: "load", message: error.message });
    } finally {
      setLoading(false);
    }
  }

  function deleteSearchFilterItemHandler(id) {
    setSearchFilters((prevSearchFilters) => {
      return prevSearchFilters.filter((item) => item.id != id);
    });
  }

  useEffect(() => {
    getSearchFilters();
  }, []);

  return (
    <div>
      <Modal title="All Filters" open={open} handleClose={onClose}>
        {searchFilters.map((item) => {
          return (
            <FilterItem
              key={item.id}
              item={item}
              onDelete={deleteSearchFilterItemHandler}
              onSelect={onSelect}
              onError={(msg) =>
                setError(msg ? { type: "delete", message: msg } : null)
              }
            />
          );
        })}
        {error && error.type === "load" && (
          <ErrorView title="Error" message={error.message} />
        )}
        {loading && searchFilters.length === 0 && <LoadingSkeleton />}
        {!loading && searchFilters.length === 0 && !error && (
          <EmptyView message={"Nothing here."} />
        )}
      </Modal>
    </div>
  );
}
