import { useEffect, useState, useContext } from "react";

import classes from "./CategoryDiscovery.module.css";
import { DiscoveryContext } from "../Discovery/discovery-context";
import Discovery from "../Discovery/Discovery";

export default function CategoryDiscovery() {
  const [keyPlayerSelected, setKeyPlayerSelected] = useState("");
  const [selectedBrandsProducts, setSelectedBrandProducts] = useState([]);

  const discoveryCtx = useContext(DiscoveryContext);

  function handleProductBrandSelection(manualBrandProduct) {
    setSelectedBrandProducts((prevBrandProducts) => {
      const index = prevBrandProducts.findIndex((item) => {
        return item.name === (manualBrandProduct ? manualBrandProduct : keyPlayerSelected.name);
      });

      if (index === -1) {
        let brandProductToBeAdded = manualBrandProduct
          ? { name: manualBrandProduct, domain: "" }
          : keyPlayerSelected;
        const updatedBrandProducts = [
          brandProductToBeAdded,
          ...prevBrandProducts,
        ];
        discoveryCtx.updatedCompetitors(updatedBrandProducts);
        return updatedBrandProducts;
      } else {
        return prevBrandProducts;
      }
    });
  }

  function handleDeleteBrandProduct(itemName) {
    setSelectedBrandProducts((prevBrandProducts) => {
      return prevBrandProducts.filter((item) => item.name != itemName);
    });
  }

  function handleKeyPlayerChange(value) {
    setKeyPlayerSelected(value);
    discoveryCtx.updateEntity(value);
  }

  useEffect(() => {
    discoveryCtx.updateType("category");
  }, []);

  return (
    <div className={classes.container}>
      <Discovery
        selectedBrandsProducts={selectedBrandsProducts}
        onProductBrandSelection={handleProductBrandSelection}
        onKeyPlayerChange={handleKeyPlayerChange}
        onDelete={handleDeleteBrandProduct}
      />
    </div>
  );
}
