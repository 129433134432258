import { useEffect, useContext, useState, useRef } from "react";
import { Skeleton } from "@mui/material";

import classes from "./Description.module.css";
import { DiscoveryContext } from "../discovery-context";
import ErrorMessage from "./../../../Component/ErrorMessage/ErrorMessage";
import api from "../../../BrandOnBoarding/api";

export default function Description({ entity }) {
  const abortControllerRef = useRef(null);
  const [error, setError] = useState();

  const discoveryCtx = useContext(DiscoveryContext);

  async function getBrandDescription() {
    setError(null);

    abortControllerRef.current = new AbortController();

    const payload = {
      llm: discoveryCtx.llm,
      prompt: `Provide a 50-word description for ${entity}. Respond only with the description, without any introductory phrases or qualifiers`,
    };

    try {
      let response = await api.post(
        `/llm-execute`,
        payload,
        { signal: abortControllerRef.current.signal }
      );
      discoveryCtx.updateBrandDescription(response.data.data);
    } catch (error) {
      console.log("Something went wrong", error);
      setError(error.message);
    }
  }

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  useEffect(() => {
    if (discoveryCtx?.brand_description.length > 0) return;
    getBrandDescription(entity);

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [entity, discoveryCtx?.brand_description.length]);

  let brandDescription = null;
  let loading = true;
  if (discoveryCtx?.brand_description.length > 0) {
    brandDescription = discoveryCtx.brand_description;
    loading = false;
  }

  return (
    <div className={classes.text}>
      {loading && !error && (
        <>
          <Skeleton />
          <Skeleton style={{ width: "50%" }} />
        </>
      )}
      {!error && !loading && brandDescription}
      {error && (
        <ErrorMessage
          message={error}
          retry
          align="left"
          onRetry={() => getBrandDescription()}
        />
      )}
    </div>
  );
}
