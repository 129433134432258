import MUIChip from "../../../Component/UI/MUIChip/MUIChip";
import classes from "./Suggestions.module.css";

export default function Suggestions({ options, onDelete }) {
  return (
    <div className={classes.container}>
      <span>Products Added: </span>
      {options.map((item) => (
        <MUIChip
          key={item.name}
          label={item.name}
          className={classes.chip}
          onDelete={()=> onDelete(item.name)}
        />
      ))}
    </div>
  );
}