import React, { useState, useRef, useEffect } from "react";
import Zoom from "@mui/material/Zoom";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import ClickAwayListener from "@mui/material/ClickAwayListener";

import classes from "./MUIToolTip.module.css";
import api from "../../../BrandOnBoarding/api";
import LoadingSkeleton from "./LoadingSkeleton";
import Sources from "./Sources";
import { LLM_OPTIONS } from "../../../utils/constant";
import MUIButton from "./../MUIButton/MUIButton";

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#353945",
    color: "#FCFCFD",
    boxShadow: theme.shadows[1],
    fontSize: 11,
    maxWidth: 500,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "#353945"
  },
}));

export default function MUIToolTip({
  children,
  showSources,
  title = "",
  additionalText = "",
  showSnippets = false,
  llmSources = [],
  llm = "gpt-4o",
  actionText = "",
  disableAction = false,
  ...props
}) {
  const [loading, setLoading] = useState(true);
  const [sources, setSources] = useState([]);
  const [open, setOpen] = useState(false);

  const abortCtrlRef = useRef();

  function handleTooltipOpen() {
    setOpen(true);
  }

  function handleTooltipClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (!open) {
      if (abortCtrlRef.current) {
        abortCtrlRef.current.abort();
      }
      setSources([]);
      return;
    }
    const timer = setTimeout(() => {
      getSources();
    }, 1000);
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [open]);

  async function getSources() {
    abortCtrlRef.current = new AbortController();
    try {
      setLoading(true);
      const payload = {
        text_content: `
        ${additionalText}

        AdditionInfo: ${title}
        `,
      };
      const response = await api.post("/metrics/sources", payload, {
        signal: abortCtrlRef.current.signal,
      });
      setSources(response.data.citations);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function ToolTipContent({ llm, actionText, disableAction }) {
    const llmName = LLM_OPTIONS.find((option) => option.value === llm)?.name;
    return (
      <div className={classes.content}>
        <p>{title}</p>
        <h6>Sources</h6>
        {llmSources.length > 0 && (
          <>
            <span className="mb-2">{llmName}</span>
            <Sources sources={llmSources} showSnippets={showSnippets} />
          </>
        )}
        <span className="mb-2">Online</span>
        {loading && <LoadingSkeleton />}
        {!loading && <Sources sources={sources} showSnippets={showSnippets} />}
        {!loading && actionText && sources.length > 0 && (
          <div
            title="You need to setup monitoring for this brand/product to enable this option"
            style={{ cursor: disableAction ? "not-allowed" : "default" }}
            className={classes.action_btn_wrapper}
          >
            <MUIButton
              disabled={disableAction}
              size="small"
              className={classes.action_btn}
              variant="outlined"
            >
              {actionText}
            </MUIButton>
          </div>
        )}
      </div>
    );
  }

  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={classes.tooltip_wrapper}>
        <LightTooltip
          open={open}
          onOpen={handleTooltipOpen}
          onClose={handleTooltipClose}
          slots={{
            transition: Zoom,
          }}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClick={() => setOpen(true)}
          title={
            showSources ? (
              <ToolTipContent
                actionText={actionText}
                disableAction={disableAction}
                llm={llm}
              />
            ) : (
              title
            )
          }
          {...props}
        >
          {children}
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
}
