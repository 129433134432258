import React, { useState, useContext, useEffect } from "react";
import DateRangePicker from "../DateRangePicker";
import { AuthContext } from "../../../../context/auth-context";
import { getActionMetricsData } from "../../../../services/apiService";

import { Spinner } from "react-bootstrap";
import EmptyView from "../../../../Component/EmptyView/EmptyView";
import FilterSelection from "../../common/FilterSelection"; // Adjust the path if needed
import { ActionTable } from "./ActionTable";
import { fetchActionMetrics } from "../../../../redux/actions/metricActions";
import { useSelector, useDispatch } from "react-redux";
import ActionList from "./ActionList";
import { Button } from "@mui/material";

const Actions = () => {
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;

  const dispatch = useDispatch();

  const actionMetrics = useSelector((state) => state.actionMetrics);

  const [selectedSubFrequencyItem, setSelectedSubFrequencyItem] =
    useState("Current");
  const [isLoading, setIsLoading] = useState(false);
  const [actionMetricsData, setActionMetricsData] = useState([]);
  const [isActionList, setIsActionList] = useState(false);

  const [filters, setFilters] = useState({
    brand: [],
    llm: [],
    market: [],
    geo: [],
    persona: [],
  });

  const handlesubFrequencyCheckChange = (key) => {
    setSelectedSubFrequencyItem(key);
  };

  useEffect(() => {
    if (actionMetrics?.data && actionMetrics?.data.length === 0) {
      dispatch(fetchActionMetrics(customerId));
    }
  }, []);

  console.log("test==>", actionMetrics?.data);

  useEffect(() => {
    if (selectedSubFrequencyItem) {
      fetchActionMetricsData();
    }
  }, [selectedSubFrequencyItem]);

  const handleFilterChange = (key, selectedOptions) => {
    const values = selectedOptions.map((option) => option.value);
    setFilters((prevFilters) => ({ ...prevFilters, [key]: values }));
  };

  const fetchActionMetricsData = async () => {
    const payload = {
      customerId,
      timePeriod: selectedSubFrequencyItem,
    };
    try {
      setIsLoading(true);
      const data = await getActionMetricsData(payload);

      setActionMetricsData(data?.finishedActionsData);
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  const applyFilters = (data) => {
    return data.filter((item) => {
      return (
        (!filters.brand.length || filters.brand.includes(item?.brand)) &&
        (!filters.llm.length || filters.llm.includes(item?.llm)) &&
        (!filters.market.length || filters.market.includes(item?.market)) &&
        (!filters.geo.length || filters.geo.includes(item?.geography)) &&
        (!filters.persona.length || filters.persona.includes(item?.persona))
      );
    });
  };

  function prepareSelectOptions(dataArray) {
    const options = {
      llm: new Set(),
      persona: new Set(),
      market: new Set(),
      geo: new Set(),
      brand: new Set(),
    };

    dataArray.forEach((item) => {
      if (item.llm) options.llm.add(item.llm);
      if (item.persona) options.persona.add(item.persona);
      if (item.market) options.market.add(item.market);
      if (item.geography) options.geo.add(item.geography);
      if (item.brand) options.brand.add(item.brand);
    });

    // Convert sets to the desired array format
    const formattedOptions = Object.fromEntries(
      Object.entries(options).map(([key, values]) => [
        key,
        [...values].map((value) => ({ value, label: value })),
      ])
    );

    return formattedOptions;
  }

  const optionsMapping = prepareSelectOptions(actionMetricsData);

  return (
    <>
      <div
        className="d-flex justify-content-between"
        style={{ marginBottom: "-15px" }}
      >
        <DateRangePicker
          activeTarget={selectedSubFrequencyItem}
          handleChange={handlesubFrequencyCheckChange}
        />

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsActionList(!isActionList);
          }}
          style={{ height: "40px", marginRight: "20px" }}
        >
          {isActionList ? "Actions" : "Action List"}
          {/* Action List */}
        </Button>
      </div>

      {!isActionList ? (
        <div>
          {/* <div className="d-flex" style={{ marginBottom: "-15px" }}>
            <DateRangePicker
              activeTarget={selectedSubFrequencyItem}
              handleChange={handlesubFrequencyCheckChange}
            />
          </div> */}

          <div className="row mt-4">
            {/* <h5>Filters</h5> */}
            {["brand", "llm", "market", "geo", "persona"].map((filterKey) => (
              <div className="col-md-2" key={filterKey}>
                <FilterSelection
                  options={optionsMapping[filterKey]}
                  type={filterKey}
                  selectedOptions={filters[filterKey].map((value) => ({
                    value,
                    label: value,
                  }))}
                  handleChange={(selectedOptions) =>
                    handleFilterChange(filterKey, selectedOptions)
                  }
                />
              </div>
            ))}
          </div>

          <div className="col-md-12 mt-4">
            {isLoading ? (
              <div
                style={{
                  fontSize: "19px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp; Loading...
              </div>
            ) : (
              <div className="col-md-12 mt-4">
                <div className="card p-3 custCard">
                  {actionMetricsData?.length === 0 ? (
                    <EmptyView message="Nothing here" />
                  ) : (
                    <ActionTable
                      tableType="opp"
                      actionMetricsData={applyFilters(actionMetricsData)}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={{ marginTop: "40px" }}>
          <ActionList />
        </div>
      )}
    </>
  );
};

export default Actions;
