import React, { useState, useEffect } from "react";
import {
  prepareComparisonTableData,
  transformDataForMultibarChart,
  extractDomain,
  generateDashboardJsonPrompt,
} from "../../../utils/helpers";
import ReMultibarChart from "../common/ReMultibarChart";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortUp, faSortDown } from "@fortawesome/free-solid-svg-icons";
import classes from "./DashboardComponent.module.css";
import { metricExplainations, titleMapping } from "../../../utils/constant";
import CustomTooltip from "../common/CustomTooltip";
import EmptyView from "../../../Component/EmptyView/EmptyView";
import { getDashboardSummary } from "../../../services/apiService";
import Markdown from "markdown-to-jsx";
import { Spinner } from "react-bootstrap";
import MUIButton from "./../../../Component/UI/MUIButton/MUIButton";
import AutoAwesomeOutlinedIcon from "@mui/icons-material/AutoAwesomeOutlined";
import { Tooltip } from "@mui/material";

export const dashboardTypeMapping = {
  llm: { type: "LLM", dashboardType: "llmDashboard" },
  market: { type: "Market", dashboardType: "marketDashboard" },
  personas: { type: "Persona", dashboardType: "personaDashboard" },
};

const MultiComparisonComponent = ({
  compCoreAttributes,
  pastCompCoreAttributes,
  compCategoryDimensions,
  pastCompCategoryDimensions,
  brandIndex,
  brandReach,
  brandFav,
  pastIndexScoreObj,
  positiveAttributes,
  negativeAttributes,
  llmDomains,
  geoLogo,
  activeTab,
  brandDomains,
  brandProduct,
}) => {
  const [isChecked, setIsChecked] = useState(false);
  const [isDeltaDisplay, setIsDeltaDisplay] = useState(false);
  const [indexScore, setIndexScore] = useState();
  const [reachScore, setReachScore] = useState();
  const [favScore, setFavScore] = useState();
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    let index = brandIndex;
    let reach = brandReach;
    let fav = brandFav;
    if (!isChecked) {
      index = filteredCompData(brandIndex, brandProduct);
      reach = filteredCompData(brandReach, brandProduct);
      fav = filteredCompData(brandFav, brandProduct);
    }

    setIndexScore(index);
    setReachScore(reach);
    setFavScore(fav);
  }, [isChecked]);

  // Function to toggle the checkbox
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDeltaCheckboxChange = () => {
    setIsDeltaDisplay(!isDeltaDisplay);
  };

  const filteredCompData = (data, mainBrand) => {
    if (Object?.keys(data).length) {
      let obj = {};
      obj[mainBrand] = data[mainBrand];
      return obj;
    }
  };

  function filterPurchaseDims(DATA1, DATA2) {
    // Extract attributes from DATA1
    const data1Attributes = new Set(DATA1.map((item) => item.attribute));
    for (const llm in DATA2) {
      if (DATA2.hasOwnProperty(llm)) {
        DATA2[llm] = Object.fromEntries(
          Object.entries(DATA2[llm]).filter(([attribute]) =>
            data1Attributes.has(attribute)
          )
        );
      }
    }

    return DATA2; // Return the modified DATA2
  }

  const tableData = prepareComparisonTableData(
    compCoreAttributes,
    indexScore,
    reachScore,
    favScore,
    compCategoryDimensions,
    pastCompCoreAttributes,
    pastIndexScoreObj?.indexScore,
    pastIndexScoreObj?.rank,
    pastIndexScoreObj?.fav,
    pastCompCategoryDimensions
  );

  const positiveAttBarChartData =
    transformDataForMultibarChart(positiveAttributes);
  const negativeAttBarChartData =
    transformDataForMultibarChart(negativeAttributes);

  const chartDataArray = [positiveAttBarChartData, negativeAttBarChartData];

  const fetchSummary = async (brandIndex, brandReach, brandFav) => {
    setIsLoading(true);

    const purchaseCriterias = filterPurchaseDims(
      tableData?.tables?.[4]?.rows,
      compCategoryDimensions
    );

    try {
      const jsonData = generateDashboardJsonPrompt(
        brandProduct,
        dashboardTypeMapping[activeTab]?.type,
        JSON.stringify(brandIndex),
        JSON.stringify(brandReach),
        JSON.stringify(brandFav),
        JSON.stringify(positiveAttBarChartData),
        JSON.stringify(negativeAttBarChartData),
        JSON.stringify(compCoreAttributes),
        JSON.stringify(purchaseCriterias)
      );
      const payload = {
        entity: brandProduct,
        dashboardType: dashboardTypeMapping[activeTab]?.dashboardType,
        jsonData,
      };
      const response = await getDashboardSummary(payload);
      setSummary(response);
    } catch (error) {
      console.error("Error fetching summary:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const getTruncatedText = () => {
    const words = summary?.split(" ");
    return words.slice(0, 30).join(" ") + (words.length > 50 ? "...." : "");
  };

  if (!tableData) {
    return;
  }

  return (
    <>
      <br />

      <div className="col-md-12">
        <div className="graphGreyBg">
          <p style={{ display: "flex", justifyContent: "center" }}>
            <h4>Headlines & Focus Areas</h4>
            &nbsp; &nbsp;
            <MUIButton
              onClick={() => {
                fetchSummary(indexScore, reachScore, favScore);
              }}
              variant="outlined"
              className={classes.regenerate}
              startIcon={<AutoAwesomeOutlinedIcon />}
            >
              Generate
            </MUIButton>
          </p>
          <div className="graphsummary font-11">
            {isLoading ? (
              <div
                style={{
                  fontSize: "19px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "20px",
                  // color: "green",
                }}
              >
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                &nbsp; Loading...
              </div>
            ) : summary && typeof summary === "string" ? (
              <>
                <Markdown className="markTable">
                  {isExpanded ? summary : getTruncatedText()}
                </Markdown>
                <span
                  style={{ cursor: "pointer", color: "blue" }}
                  onClick={() => setIsExpanded(!isExpanded)}
                >
                  {isExpanded ? "Show Less" : "Show More"}
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>

      <div
        style={{ fontSize: "15px", marginTop: "12px", marginBottom: "-4px" }}
      >
        <label>
          <input
            style={{}}
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          &nbsp;
          {"Show Competitor"}
        </label>
        &nbsp;
        <label>
          <input
            style={{}}
            type="checkbox"
            checked={isDeltaDisplay}
            onChange={handleDeltaCheckboxChange}
          />
          &nbsp;
          {"Show Change Data"}
        </label>
      </div>

      <div className="col-md-12 mt-4">
        <div
          style={{
            position: "relative",
            maxHeight: "400px",
            overflowY: "auto",
          }}
          className="card custCard"
        >
          <table className="table table-striped">
            <thead
              style={{
                position: "sticky",
                // zIndex: 10,
                top: 0,
              }}
            >
              <tr className="tblHeader text-center">
                <th
                  scope="col"
                  style={{
                    width: "150px",
                    textAlign: "left",
                    paddingLeft: "15px",
                    verticalAlign: "middle",
                  }}
                >
                  {/* <Tooltip title={metricExplainations["RBI"]}>RBI</Tooltip> */}
                </th>

                {tableData.headers.map((header, idx) => {
                  const extractGeo = header
                    .split("(")[0]
                    .trim()
                    .replace(/\s+/g, "");
                  return (
                    <th
                      scope="col"
                      key={idx}
                      className={classes["comp-logo-header"]}
                    >
                      <div className={classes["comp-logos"]}>
                        {activeTab !== "llm" ? (
                          geoLogo?.[extractGeo] && (
                            <img
                              className={classes?.logo}
                              src={`https://flagcdn.com/40x30/${geoLogo?.[extractGeo]}.png`}
                              alt={""}
                            />
                          )
                        ) : (
                          <img
                            className={classes?.logo}
                            src={`https://img.logo.dev/${extractDomain(
                              llmDomains?.[header]
                            )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                            alt={""}
                          />
                        )}
                        <span
                          style={{
                            wordWrap: "break-word", // Ensures long text wraps
                            textAlign: "center",
                            marginTop: "5px", // Adds space between logo and text
                          }}
                        >
                          {header}
                        </span>
                      </div>
                    </th>
                  );
                })}
              </tr>
            </thead>

            <tbody>
              {tableData.tables.map((table, tableIndex) => (
                <React.Fragment key={tableIndex}>
                  {tableIndex !== 0 && <br />}
                  <tr className="tblHeader">
                    {/* {tableIndex !== 0 && ( )} */}
                    <th
                      style={{ textAlign: "left", paddingLeft: "15px" }}
                      colSpan={tableData.headers.length + 1}
                    >
                      <Tooltip
                        title={metricExplainations[titleMapping[table.title]]}
                      >
                        <span
                          style={{
                            color: "black",
                            fontSize: "1.2rem",
                            fontWeight: "500",
                          }}
                        >
                          {titleMapping[table.title]}
                        </span>
                      </Tooltip>
                      {/* {titleMapping[table.title]} */}
                    </th>
                  </tr>
                  {table?.rows && table?.rows?.length ? (
                    table.rows?.map((row, idx) => (
                      <tr key={idx}>
                        {table.title !== "Core Attributes" &&
                        brandDomains?.[row?.attribute] ? (
                          <>
                            <td
                              valign="middle"
                              style={{
                                width: "150px",
                                textAlign: "left",
                                paddingLeft: "15px",
                              }}
                            >
                              <img
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "6px",
                                  marginBottom: "5px", // Adds spacing between logo and text
                                }}
                                src={`https://img.logo.dev/${extractDomain(
                                  brandDomains?.[row?.attribute]
                                )}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                                alt={""}
                              />
                              &nbsp;
                              {row.attribute}
                            </td>
                          </>
                        ) : (
                          <td
                            valign="middle"
                            style={{
                              width: "150px",
                              textAlign: "left",
                              paddingLeft: "15px",
                            }}
                          >
                            <Tooltip title={metricExplainations[row.attribute]}>
                              {row.attribute}
                            </Tooltip>
                          </td>
                        )}

                        {row.values.map((val, valIdx) => {
                          const isColorApply =
                            table?.title == "RBI" ||
                            table?.title == "Rank" ||
                            table?.title == "Favorability";
                          let color = "#dc3545";
                          if (Number(val?.value).toFixed(0) >= 7)
                            color = "#2196F3";
                          if (Number(val?.value).toFixed(0) >= 9)
                            color = "#198754";

                          return (
                            <td
                              key={valIdx}
                              style={{
                                width: "100px",
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <CustomTooltip explaination={val?.explaination}>
                                <div className="d-flex align-items-center justify-content-center flex-row">
                                  <span
                                    style={{
                                      color:
                                        !isColorApply && !val?.isNa
                                          ? color
                                          : "grey",
                                    }}
                                    className={`h5 mb-0 me-1  ${classes["main-value"]}`}
                                  >
                                    {!val?.isNa
                                      ? Number(val?.value).toFixed(0)
                                      : "NA"}
                                  </span>

                                  {val?.change != undefined &&
                                    !val?.isNa &&
                                    isDeltaDisplay && (
                                      <>
                                        <FontAwesomeIcon
                                          className={
                                            val?.change < 0
                                              ? `${classes["down-icon"]} text-danger me-1`
                                              : `${classes["up-icon"]} text-success me-1`
                                          }
                                          icon={
                                            val?.change < 0
                                              ? faSortDown
                                              : faSortUp
                                          }
                                        />
                                        <span className={classes.delta}>
                                          {val?.change > 0
                                            ? `${Number(val?.change).toFixed(
                                                0
                                              )}`
                                            : Number(
                                                Math.abs(val?.change)
                                              ).toFixed(0)}
                                        </span>
                                      </>
                                    )}
                                </div>
                              </CustomTooltip>
                            </td>
                          );
                        })}
                      </tr>
                    ))
                  ) : (
                    <EmptyView message="Data Not Available" />
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {chartDataArray?.map((data, index) => {
        return (
          <div className="col-md-12 mt-4">
            <div className="card p-3 custCard">
              <div className="mb-3 d-flex justify-content-between align-items-center"></div>
              <div>
                <ReMultibarChart index={index} data={data} />
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default MultiComparisonComponent;
