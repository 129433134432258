import * as React from "react";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

export default function RadioButtonsGroup({
  options,
  title,
  direction = "row",
  onChange,
  ...props
}) {
  let flexDirection = "flex-row";
  if (direction == "column") flexDirection = "flex-column";

  return (
    <FormControl {...props}>
      {title && (
        <FormLabel id="demo-radio-buttons-group-label">{title}</FormLabel>
      )}
      <RadioGroup
        defaultValue={options[0].value}
        name="radio-buttons-group"
        className={`d-flex ${flexDirection}`}
        onChange={(e)=> onChange(e.target.value)}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
}
