import React, { useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CachedIcon from "@mui/icons-material/Cached";
import classes from "./Actions.module.css";
import Table, {
  StyledTableCell,
  StyledTableRow,
} from "./../../../../Component/UI/Table/Table";

import MUIIconButton from "./../../../../Component/UI/MUIIconButton/MUIIconButton";
import { AuthContext } from "../../../../context/auth-context";
import Spinner from "./../../../../Component/Spinner/Spinner";
import {
  deleteActionMetric,
  fetchActionMetrics,
} from "../../../../redux/actions/metricActions";

const TABLE_HEADERS = [
  { id: "Actions", name: "Actions", sorting: true },
  { id: "details", name: "Details", sorting: false },
  { id: "actions", name: "Manage", sorting: false },
];

const ActionList = () => {
  const dispatch = useDispatch();
  const actionMetrics = useSelector((state) => state.actionMetrics);
  const { user } = useContext(AuthContext);
  const customerId = user?.customer_id || 0;

  useEffect(() => {
    if (actionMetrics?.data && actionMetrics?.data?.actionListlength === 0) {
      dispatch(fetchActionMetrics(customerId));
    }
  }, [dispatch]);

  const actionMetricsData = actionMetrics?.data?.actionList;

  console.log("testting==>", actionMetrics, actionMetricsData);

  const renderRow = (item) => {
    const data = item?.action_json;
    return (
      <StyledTableRow key={item.id}>
        <StyledTableCell component="th" scope="row" style={{ width: 450 }}>
          <div className={classes.brand_product}>
            <span> {data?.title} </span>
          </div>
        </StyledTableCell>
        <StyledTableCell align="left" style={{ width: 450 }}>
          {data?.title}
        </StyledTableCell>

        <StyledTableCell align="left">
          <div>
            <MUIIconButton
              style={{ color: "#007aff" }}
              className={classes.action_btn}
              tooltip="Update"
              // onClick={() => {
              //   setPersonasName(item.persona.name);
              //   setpersonasDetails(item.persona.description);
              //   setModalOpen(true);
              //   setUpdateModalOpen(true);
              //   setSelectPersonasID(item);
              // }}
            >
              <CachedIcon />
            </MUIIconButton>
            <MUIIconButton
              style={{ color: "#da1a04" }}
              tooltip="Delete"
              className={classes.action_btn}
              onClick={() => handleDeleteActionMetric(item.id)}
            >
              <DeleteOutlineIcon />
            </MUIIconButton>
          </div>
        </StyledTableCell>
      </StyledTableRow>
    );
  };

  const handleDeleteActionMetric = (actionId) => {
    dispatch(deleteActionMetric(customerId, actionId)).then(() => {
      dispatch(fetchActionMetrics(customerId));
    });
  };
  return (
    <>
      {actionMetrics.loading && <Spinner />}
      {!actionMetrics.loading && (
        <Table
          headers={TABLE_HEADERS}
          data={actionMetricsData}
          render={renderRow}
        />
      )}
    </>
  );
};

export default ActionList;
