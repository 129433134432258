import { combineReducers } from "redux";
import brandsReducer from "./brandsReducer";
import brandProductsReducer from "./brandProductsReducer";
import brandCategoriesReducer from "./brandCategoriesReducer";
import personasReducer from "./personasReducer";
import competitorBrandsReducer from "./competitorBrandsReducer";
import competitorProductsReducer from "./competitorProductReducer";
import productCategoriesReducer from "./productCategoriesReducer";
import globalCategoriesReducer from "./categoriesReducer";
import UserReducer from "./userReducer";
import coreAttributesReducer from "./coreAttributesReducer";
import { LOGOUT } from "../constants/actionTypes";
import geoMarketsReducer from "./geoMarketsReducer";
import actionMetricsReducer from "./metricActions";

const appReducer = combineReducers({
  brands: brandsReducer,
  brandProducts: brandProductsReducer,
  brandCategories: brandCategoriesReducer,
  personas: personasReducer,
  competitorBrands: competitorBrandsReducer,
  competitorProducts: competitorProductsReducer,
  productCategories: productCategoriesReducer,
  user: UserReducer,
  globalCategories: globalCategoriesReducer,
  coreAttributes: coreAttributesReducer,
  geoMarkets: geoMarketsReducer,
  actionMetrics: actionMetricsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
