import React, { useState } from "react";
import classes from "./Actions.module.css";

import ActionModal from "./ActionModal";

export const ActionTable = ({ actionMetricsData }) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState();
  const [actionType, setActionType] = useState();

  const handleClose = () => setOpen(false);

  const handleModalData = (data, type) => {
    setOpen(true);
    setModalData(data);
    setActionType(type);
  };

  const headers = [
    "Brand",
    "LLM",
    "Persona",
    "Geography",
    "Market",
    "Reach",
    "Risk",
    "Opportunity",
  ];

  return (
    <div className="col-md-12">
      <ActionModal
        type={actionType}
        data={modalData}
        open={open}
        handleClose={handleClose}
      />

      <div className={classes.tableContainer}>
        <table className="table table-striped">
          <thead
            style={{
              position: "sticky",
              // zIndex: 10,
              top: 0,
            }}
          >
            <tr style={{ textAlign: "center" }} className="prdtblHeader">
              {headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {actionMetricsData?.map((data, index) => (
              <tr style={{ textAlign: "center" }} key={index}>
                <td>{data?.brand}</td>

                <td>{data?.llm}</td>
                <td>{data?.persona}</td>
                <td>{data?.geography}</td>
                <td>{data?.market}</td>
                <td
                  onClick={() => {
                    handleModalData(data, "reach");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {data?.reachCount || "--"}
                </td>
                <td
                  onClick={() => {
                    handleModalData(data, "risk");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {data?.nonReachRiskCount || "--"}
                </td>
                <td
                  onClick={() => {
                    handleModalData(data, "opp");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {data?.oppCount || "--"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};
