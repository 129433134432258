import { useContext, useState, useEffect, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";
import { CircularProgress } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

import TextInput from "./../../Component/UI/TextInput/TextInput";
import MUIButton from "../../Component/UI/MUIButton/MUIButton";
import RadioGroup from "../../Component/UI/RadioGroup/RadioGroup";
import MUISelect from "../../Component/UI/MUISelect/MUISelect";
import classes from "./Form.module.css";
import { DiscoveryContext } from "./discovery-context";
import { LLM_OPTIONS } from "../../utils/constant";
import { validateBrandInput } from "../../utils/helpers";
import MUIChip from "./../../Component/UI/MUIChip/MUIChip";
import MUIIconButton from "../../Component/UI/MUIIconButton/MUIIconButton";
import MUIAutocomplete from "../../Component/UI/MUIAutocomplete/MUIAutocomplete";

const RADIO_OPTIONS = [
  {
    value: "brand",
    label: "Brand",
  },
  {
    value: "product",
    label: "Product",
  },
  /*{
    value: "market",
    label: "Market Research",
  },*/
];

export default function Form({
  onSubmit,
  disabled,
  onTypeChanged = () => {},
  onCategoryChange = () => {},
  onKeyPlayerChange = () => {},
  onProductBrandSelection = () => {},
  showBrandInput = true,
  keyPlayers,
}) {
  const [brand, setBrand] = useState("");
  const [category, setCategory] = useState("");
  const [market, setMarket] = useState("");
  const [llm, setLLM] = useState("gpt-4o");
  const [type, setType] = useState("brand");
  const [loading, setLoading] = useState(false);
  const [isInputValid, setIsInputValid] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [keyPlayerInput, setKeyPlayerInput] = useState('');

  const discoveryCtx = useContext(DiscoveryContext);
  const timerRef = useRef();

  async function checkIfInputValid(input) {
    setLoading(true);
    setSuggestions([]);
    try {
      let response = await validateBrandInput(input);
      setLoading(false);
      if (response.data.ans === "Yes") {
        setIsInputValid(true);
      } else {
        setSuggestions(response?.data?.correct_inputs);
        setIsInputValid(false);
      }
    } catch (error) {
      setLoading(false);
    }
  }

  function handleSuggestionClick(text) {
    setBrand(text);
    setIsInputValid(true);
    setSuggestions([]);
  }

  function handleBrandInputChange(e) {
    setIsInputValid(false);
    setBrand(e.target.value);
  }

  function handleCategoryChange(e) {
    setCategory(e.target.value);
    //onCategoryChange(e.target.value);
    discoveryCtx.updateCategory(e.target.value)
  }

  function handleDiscoveryTypeChange(value) {
    setType(value);
    onTypeChanged(value);
    discoveryCtx.updateType(value);

    setBrand("");
    setCategory("");
    setLLM("gpt-4o");
  }

  useEffect(() => {
    if (!brand || isInputValid) return;
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      checkIfInputValid(brand);
    }, 1000);
  }, [brand, isInputValid]);

  return (
    <div className={classes.form}>
      {discoveryCtx.type != "category" && (
        <RadioGroup
          className={classes.type_radio}
          options={RADIO_OPTIONS}
          onChange={handleDiscoveryTypeChange}
        />
      )}
      <div className={classes.input_group}>
        {discoveryCtx.type != "category" && (
          <div className={classes.brand_input}>
            <TextInput
              fullWidth
              value={brand}
              error={!isInputValid && brand != "" && !loading}
              label={type === "brand" ? "Brand*" : "Product*"}
              placeholder={type === "brand" ? "Nike" : "Nike Air Jordan 1"}
              onChange={handleBrandInputChange}
            />
            {loading && (
              <CircularProgress className={classes.loading} size={15} />
            )}
          </div>
        )}

        <TextInput
          label="Category*"
          placeholder="Athletic Apparel"
          onChange={handleCategoryChange}
        />
        <TextInput
          label="Market"
          placeholder="North America"
          onChange={(e) => setMarket(e.target.value)}
        />
        <MUISelect
          label={"LLM*"}
          defaultValue="gpt-4o"
          options={LLM_OPTIONS}
          onChange={(e) => setLLM(e.target.value)}
        />
        {discoveryCtx.type === "category" && (
          <div className="d-flex flex-row justify-space-between">
            <MUIAutocomplete
              fullWidth
              label={"Key Players*"}
              options={keyPlayers}
              onInputChange={val=> setKeyPlayerInput(val)}
              onChange={onKeyPlayerChange}
            />
            <MUIIconButton onClick={()=> onProductBrandSelection(keyPlayerInput)}>
              <AddIcon />
            </MUIIconButton>
          </div>
        )}
        <MUIButton
          variant="contained"
          color={discoveryCtx.loading ? "error" : "primary"}
          disabled={(!isInputValid || loading) && discoveryCtx.type !== "category"}
          onClick={() => {
            if ((!brand && discoveryCtx.type !== "category") || !category || !llm) {
              toast.warn("Please fill all required fields");
              return;
            }

            if (discoveryCtx.loading) {
              discoveryCtx.updateAbortLaunch(true);
              return;
            }

            onSubmit({
              brand,
              category,
              market,
              llm,
              type,
            });
          }}
        >
          {discoveryCtx.loading ? "Cancel" : "Launch"}
        </MUIButton>
      </div>
      {suggestions.length > 0 && (
        <span className={classes.hint_text}>
          Did you mean{" "}
          <MUIChip
            onClick={() => handleSuggestionClick(suggestions[0])}
            className={classes.hint_chip}
            label={suggestions[0]}
          ></MUIChip>
        </span>
      )}
      <ToastContainer />
    </div>
  );
}
