import { useEffect, useContext, useState, useRef } from "react";
import { Skeleton } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";

import MUICard from "./../../../Component/UI/MUICard/MUICard";
import classes from "./BrandScore.module.css";
import Header from "../../../Component/Header/Header";
import GaugeChart from "./../../../Component/GaugeChart/GaugeChart";
import { DiscoveryContext } from "../discovery-context";
import { extractDomain } from "../../../utils/helpers";
import Attributes from "../Attributes/Attributes";
import MUIIconButton from "./../../../Component/UI/MUIIconButton/MUIIconButton";
import Modal from "../../../Component/Modal/Modal";
import BrandIndexBreakups from "../../../Component/BrandIndexHistory/BrandIndexBreakups";
import BrandInsights from "../BrandInsights/BrandInsights";
import LLMIndicator from "./../LLMIndicator/LLMIndicator";
import ErrorView from "../../../Component/ErrorView/ErrorView";
import AnimatedNumber from "../../../Component/AnimatedNumber/AnimatedNumber";
import MUIButton from "../../../Component/UI/MUIButton/MUIButton";
import Share from "../Share/Share";
import api from "../../../BrandOnBoarding/api";
import Alert from "../../../Component/UI/Alert/Alert";

export default function BrandScore({ category, market }) {
  const [showBrandIndexBreakups, setShowBrandIndexBreakups] = useState(false);
  const [error, setError] = useState(null);
  const [showShareModal, setShowShareModal] = useState(false);

  const discoveryCtx = useContext(DiscoveryContext);
  const entity = discoveryCtx.entity;

  const discoveryType = discoveryCtx.type || "brand";

  const abortControllerRef = useRef(null);

  async function getBrandIndex() {
    const payload = {
      llm: discoveryCtx.llm,
      category: category,
      count: 5,
      brand: discoveryCtx.type === "brand" ? "companies" : "products",
      persona: "General Profile",
      market: market,
      product: entity,
      return_meta: true,
    };

    setError(null);
    abortControllerRef.current = new AbortController();

    try {
      if (discoveryCtx.llm === "Perplexity") {
        await new Promise((res) => setTimeout(() => res(""), 60000));
      }
      const response = await api.post(
        "/metrics/v4/get-brand-index-score",
        payload,
        { signal: abortControllerRef.current.signal }
      );
      discoveryCtx.updateBrandScores({
        data: response.data.error
          ? null
          : { ...response.data.data[0], extras: response.data.extras },
        index: 0,
      });
    } catch (error) {
      setError(error.message);
    }
  }

  useEffect(() => {
    if (discoveryCtx.abort_launch) {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    }
  }, [discoveryCtx.abort_launch]);

  useEffect(() => {
    if (discoveryCtx.brand_scores.length > 0) return;
    getBrandIndex();
  }, [entity, discoveryCtx.brand_scores.length]);

  let brandScore = discoveryCtx.brand_scores[0];
  let loading = true;
  let domain = "";
  if (brandScore && brandScore.extras) {
    brandScore = discoveryCtx.brand_scores[0];
    domain = extractDomain(discoveryCtx.competitors[0]?.domain);
    loading = false;
  }
  if (brandScore && brandScore.error) {
    loading = false;
  }

  let brandScoreAdjustments = { changed: false };
  if (brandScore?.extras?.rank) {
    const [{ name }] = brandScore?.extras?.rank;
    if (
      name.toString().toLowerCase().trim().replaceAll(" ", "") !==
      discoveryCtx.entity.toString().toLowerCase().trim().replaceAll(" ", "")
    ) {
      brandScoreAdjustments.entities = [name, discoveryCtx.entity];
      brandScoreAdjustments.changed = true;
    }
  }

  return (
    <div className={classes.wrapper}>
      <MUICard variant="elevated" elevation={0} className={classes.card}>
        <div className={classes.container}>
          <div className={classes.brand_container}>
            <Header
              title={
                <div className={classes.title_container}>
                  {domain && (
                    <img
                      className={classes.logo}
                      src={`https://img.logo.dev/${domain}?token=pk_SaDf4t2eRsW5Fc84zgJRZA`}
                    />
                  )}
                  <div className="pt-2">
                    <span>{entity}</span>
                    {discoveryCtx?.category && (
                      <div className={classes.label_container}>
                        <span className={classes.title_label}>
                          {discoveryCtx?.category}
                        </span>
                        {discoveryCtx?.market ? (
                          <>
                            <span>&middot;</span>
                            <span className={classes.title_label}>
                              {discoveryCtx?.market}
                            </span>
                          </>
                        ) : (
                          <span>&nbsp;</span>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              }
              titleClassName={classes.title}
              className={classes.header}
              renderAction={() => {
                return (
                  <div className="mx-3">
                    <MUIIconButton
                      className="mx-3"
                      onClick={() => setShowShareModal(true)}
                    >
                      <ShareOutlinedIcon color="primary" />
                    </MUIIconButton>
                    {/* <MUIButton
                      data-html2canvas-ignore
                      variant={"outlined"}
                      startIcon={<DvrIcon />}
                      onClick={() => {}}
                      className={`${classes.start_monitoring} no-print`}
                    >
                      Start Monitoring
                    </MUIButton> */}
                  </div>
                );
              }}
            />
            <div className={`${classes.print_only} print-only`}>
              <LLMIndicator llm={discoveryCtx.llm || "gpt-4o"} />
            </div>
            <BrandInsights entity={entity} category={category} />
            <div className={classes.brand_index_wrapper}>
              <div className={classes.brand_index}>
                <div className={classes.main}>
                  {!loading && brandScore?.extras && !error && (
                    <MUIIconButton
                      data-html2canvas-ignore
                      className={classes.info}
                      onClick={() => setShowBrandIndexBreakups(true)}
                    >
                      <InfoOutlinedIcon />
                    </MUIIconButton>
                  )}
                  <div>
                    <span className={classes.brand_label}>
                      <span className={classes.llm_brand}>
                        {discoveryType === "brand" ? "Brand" : "Product"} Index
                        Score
                      </span>
                      <LLMIndicator llm={discoveryCtx.llm || "gpt-4o"} />
                    </span>
                    {loading && !error && <Skeleton width={400} height={350} />}
                    {!loading && brandScore?.total && !error && (
                      <GaugeChart
                        width={"100%"}
                        height={"330px"}
                        radius={"100%"}
                        center={["50%", "70%"]}
                        data={[{ value: brandScore?.total }]}
                      />
                    )}
                    {error && (
                      <div className={classes.error_view}>
                        <ErrorView
                          title={"Error fetching brand index score"}
                          message={error}
                          retry
                          onRetry={() => {
                            discoveryCtx.updateBrandScores({
                              data: null,
                              index: 0,
                            });
                            getBrandIndex();
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className={classes.sub}>
                    <div className={classes.score}>
                      <div className={classes.score_content}>
                        {!error && (
                          <span className={classes.score_text}>Reach</span>
                        )}
                        {!loading && !error && (
                          <AnimatedNumber
                            className={classes.score_value}
                            start={-1}
                            end={brandScore?.rank}
                            duration={600}
                          />
                        )}
                        {loading && !error && (
                          <Skeleton width={90} height={110} />
                        )}
                      </div>
                    </div>
                    {!error && <div className={classes.partition}></div>}
                    <div className={classes.score}>
                      <div className={classes.score_content}>
                        {!error && (
                          <span className={classes.score_text}>Sentiment</span>
                        )}
                        {!loading && !error && (
                          <AnimatedNumber
                            className={classes.score_value}
                            start={0}
                            end={brandScore?.favorability}
                            duration={600}
                          />
                        )}
                        {loading && !error && (
                          <Skeleton width={90} height={110} />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {brandScoreAdjustments.changed && (
                  <Alert
                    title="Reach Score Adjustment"
                    message={
                      <span>
                        The Reach score has been calculated using the {discoveryCtx.type}{" "}
                        <b>{brandScoreAdjustments.entities[0]}</b> rather than{" "}
                        <b>{brandScoreAdjustments.entities[1]}</b>
                      </span>
                    }
                  />
                )}
              </div>
              <div className={classes.summary}>
                <Attributes
                  entity={entity}
                  category={category}
                  market={market}
                />
              </div>
            </div>
          </div>
        </div>
        <Modal
          open={showBrandIndexBreakups}
          handleClose={() => setShowBrandIndexBreakups(false)}
          width={1200}
        >
          <BrandIndexBreakups data={brandScore?.extras} />
        </Modal>
        <Modal
          height="60%"
          title="Share Link"
          open={showShareModal}
          handleClose={() => setShowShareModal(false)}
        >
          <Share />
        </Modal>
      </MUICard>
    </div>
  );
}
