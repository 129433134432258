import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { useContext } from "react";

import "./App.css";
import { AuthContext } from "./context/auth-context";
import MainPage from "./Component/MainDataComponent/MainPage";
import AuthComponent from "./Component/AuthComponent/AuthComponent";
import ComparisonReportingComponent from "./Component/BasicCardComponent/Reporting/ComparisonReportingComponent";
import PromptCreationComponent from "./Component/PromptCreationComponent/PromptCreationComponent";
import MonitorSetup from "./Component/MonitoringComponent/MonitorSetup";
import MonitoringReport from "./Component/MonitoringComponent/MonitoringReport";
import Dashboard from "./Component/Dashboard/Dashboard";
import Dashboard2 from "./Component/devDashboard2/Dashboard2";
import Reporting from "./Component/BasicCardComponent/Reporting/Reporting";
import LlmUsageRecordComponent from "./Component/LlmUsageRecord/LlmUsageRecordComponent";
import SettingsList from "./BrandOnBoarding/Settings/SettingsList";
import HomePage from "./BrandOnBoarding/HomePage/HomePage";
import Dashboard3 from "./BrandOnBoarding/Dashboard/Dashboard";
import VerifyUser from "./Admin/VerifyUser";
import AdminLayout from "./Layout/AdminLayout";
import Home from "./Admin/Home";
import BrandReport from "./BrandReport";
import AddReport from "./Component/BasicCardComponent/Reporting/AddReport";
import ContentLayout from "./luminaire/ContentLayout";
import PromptEngine from "./luminaire/PromptEngine/PromptEngine";
import LuminaireDashboard from "./luminaire/Dashboard/Dashboard";
import Settings from "./luminaire/Settings/Settings";
import LuminaireHome from "./luminaire/HomePage/HomePage";
import Discovery from "./luminaire/Discovery/Discovery";
import Actions from "./luminaire/Actions/Actions";
import CategoryDiscovery from "./luminaire/CategoryDiscovery/CategoryDiscovery";

function App() {
  const { token, user, isCurrentUserAdmin } = useContext(AuthContext);

  const renderProtectedElement = (Component, props = {}) => {
    return token && user && user?.is_verified ? (
      <Component {...props} />
    ) : (
      <Navigate to="/login" />
    );
  };

  const renderAdminElement = (Component) => {
    return isCurrentUserAdmin ? (
      <Component />
    ) : token ? (
      <Navigate to="/luminaire/discovery" />
    ) : (
      <Navigate to="/login" />
    );
  };

  const renderLoginElement = (Component) => {
    return token && user && user?.is_verified ? (
      <Navigate to="/luminaire/discovery" />
    ) : (
      <Component />
    );
  };

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* <Route
            path="/:focusingArea?"
            element={renderProtectedElement(MainPage)}
          /> */}
          <Route
            path="/"
            element={<Navigate to="/luminaire/discovery" replace />}
          />
          <Route
            path="/add-report"
            element={renderProtectedElement(AddReport)}
          />
          <Route path="/brand-report" element={<BrandReport />} />
          <Route
            path="/llmreportdata"
            element={renderProtectedElement(ComparisonReportingComponent)}
          />
          <Route
            path="/reporting"
            element={renderProtectedElement(Reporting)}
          />
          <Route
            path="/monitors"
            element={renderProtectedElement(MonitorSetup)}
          />
          <Route
            path="/monitoringreport/:id"
            element={renderProtectedElement(MonitoringReport)}
          />
          <Route
            path="/dashboard"
            element={renderProtectedElement(Dashboard)}
          />
          <Route
            path="/dashboard2"
            element={renderProtectedElement(Dashboard2)}
          />
          <Route
            path="/brandonboarding/dashboard"
            element={renderProtectedElement(Dashboard3)}
          />
          <Route
            path="/monitors"
            element={renderProtectedElement(MonitorSetup)}
          />
          <Route
            path="/llmusagerecord"
            element={renderProtectedElement(LlmUsageRecordComponent)}
          />
          <Route
            path="/brandonboarding/settings"
            element={renderProtectedElement(SettingsList)}
          />
          <Route
            path="/brandonboarding/home"
            element={renderProtectedElement(HomePage)}
          />
          <Route
            path="/brandonboarding"
            element={renderProtectedElement(HomePage)}
          />
          <Route
            path="/monitoringreport/:id"
            element={renderProtectedElement(MonitoringReport)}
          />
          <Route
            path="/admin"
            element={renderAdminElement(PromptCreationComponent)}
          />

          <Route path="/luminaire" element={<ContentLayout />}>
            <Route index element={renderProtectedElement(Discovery)} />
            <Route
              path="home"
              element={renderProtectedElement(LuminaireHome)}
            />
            <Route
              path="brand-monitoring"
              element={renderProtectedElement(LuminaireDashboard, {
                key: "brand-monitoring",
              })}
            />
            <Route
              path="product-monitoring"
              element={renderProtectedElement(LuminaireDashboard, {
                key: "product-monitoring",
              })}
            />
            <Route path="actions" element={renderProtectedElement(Actions)} />
            <Route
              path="discovery"
              element={renderProtectedElement(Discovery)}
            />
            <Route
              path="prompt-engine"
              element={renderProtectedElement(PromptEngine)}
            />
             <Route
              path="category-discovery"
              element={renderProtectedElement(CategoryDiscovery)}
            />
            {(user?.user_role?.role?.name == "Company admin" ||
              user?.user_role?.role?.name == "Super User") && (
              <Route
                path="settings"
                element={renderProtectedElement(Settings)}
              />
            )}
          </Route>

          <Route path="/login" element={renderLoginElement(AuthComponent)} />
          <Route path="/verify-user" element={<VerifyUser />} />
          {user?.user_role?.role_id === 1 && (
            <Route path="/system" element={<AdminLayout />}>
              <Route path="" element={<Home />} />
            </Route>
          )}
          <Route
            path="*"
            element={<Navigate to="/luminaire/discovery" replace />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
