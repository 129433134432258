import React, { useReducer, useContext, useEffect, useState } from "react";

import { addDataToFirebase } from "../../DatabaseFirebase/firebaseService";
import { AuthContext } from "../../context/auth-context";

const sanitizeKey = (key) => {
  return key.replace(/[.#$\/\[\]]/g, '_');
};

const sanitizeObject = (obj) => {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }
  if (Array.isArray(obj)) {
    return obj.map(sanitizeObject);
  }
  return Object.keys(obj).reduce((result, key) => {
    const sanitizedKey = sanitizeKey(key);
    result[sanitizedKey] = sanitizeObject(obj[key]);
    return result;
  }, {});
};


function hasData(obj, type) {
  const baseExpectedTypes = {
    name: "string",
    llm: "string",
    type: "string",
    entity: "string",
    competitors: "object",
    brand_scores: "object",
    category_dimensions: "object",
    sources: "object",
    brand_description: "string",
    brand_snippets: "object",
    brand_themes: "object",
    positive_negative_attributes: "object",
  };

  let expectedTypes = { ...baseExpectedTypes };

  if (type === "brand") {
    expectedTypes.core_attributes = "object";
    expectedTypes.top_products = "object";
  } else if (type === "product") {
    expectedTypes.taxonomies = "object";
  }

  for (const key in expectedTypes) {
    if (expectedTypes.hasOwnProperty(key)) {
      const expectedType = expectedTypes[key];
      const actualValue = obj[key];
      
      // Check if the property exists in the object
      if (!(key in obj)) {
        return false;
      }

      const actualType = Array.isArray(actualValue)
        ? "object"
        : typeof actualValue;

      if (actualType !== expectedType) {
        return false;
      }

      if (expectedType === "object" && Array.isArray(actualValue)) {
        if (
          actualValue.length === 0 &&
          key !== "category_dimensions" &&
          key !== "core_attributes" &&
          key !== "positive_negative_attributes" &&
          key !== "brand_themes"
        ) {
          return false;
        }
        if (key === "brand_scores") {
          if (actualValue.length !== 4 || 
              actualValue.some(item => item === null || item === undefined || item.hasOwnProperty("error"))) {
            return false;
          }
        }
      } else if (expectedType === "object" && !Array.isArray(actualValue)) {
        // Check if the object is empty for specific keys
        if (
          (key === "category_dimensions" ||
            key === "core_attributes" ||
            key === "positive_negative_attributes" ||
            key === "brand_themes") &&
          Object.keys(actualValue).length === 0
        ) {
          return false;
        }
      } else if (expectedType === "string" && actualValue.trim() === "") {
        return false;
      }
    }
  }
  return true;
}

const INITIAL_STATE = {
  name: "",
  entity: "",
  category: "",
  market: "",
  llm: "",
  type: "brand",
  loading: false,
  competitors: [],
  brand_scores: [],
  category_dimensions: {},
  core_attributes: {},
  sources: [],
  brand_description: "",
  brand_snippets: [],
  brand_themes: [],
  positive_negative_attributes: {},
  top_products: [],
  taxonomies: [],
  is_launched: false,
  abort_launch: false,
};

export const DiscoveryContext = React.createContext({
  state: {},
  category: "",
  market: "",
  llm: "",
  type: "",
  loading: false,
  brand_scores: [],
  updateBrandScores: () => {},
  sources: [],
  updateSources: () => {},
  core_attributes: [],
  updateCoreAttributes: () => {},
  competitors: [],
  updatedCompetitors: () => {},
  category_dimensions: {},
  updateCategoryDimensions: () => {},
  brand_description: "",
  updateBrandDescription: () => {},
  brand_snippets: [],
  updateBrandSnippets: () => {},
  positive_negative_attributes: {},
  updatePositiveNegativeAttributes: () => {},
  top_products: [],
  updatedTopProducts: () => {},
  name: "",
  entity: "",
  updateEntity: () => {},
  updateName: () => {},
  updateType: () => {},
  is_launched: false,
  updatedLaunched: () => {},
  updateEntireState: () => {},
  resetState: () => {},
  brand_themes: [],
  updatedBrandThemes: () => {},
  updateCategory: () => {},
  updateMarket: () => {},
  updateLLM: () => {},
  taxonomies: {},
  updatedTaxonomies: () => {},
  abort_launch: false,
  updateAbortLaunch: () => {}
});

function discoveryReducer(state = INITIAL_STATE, action) {
  if (action.type === "UPDATE_BRAND_SCORE") {
    const { data, index } = action.payload;
    const updatedBrandScores = [...state.brand_scores];
    updatedBrandScores[index] = data;
    return {
      ...state,
      brand_scores: updatedBrandScores,
    };
  }
  if (action.type === "UPDATE_SOURCES") {
    const updatedSources = [...state.sources, ...action.payload];
    return {
      ...state,
      sources: updatedSources,
    };
  }
  if (action.type === "UPDATE_CORE_ATTRIBUTES") {
    return {
      ...state,
      core_attributes: action.payload,
    };
  }
  if (action.type === "UPDATE_COMPETITORS") {
    return {
      ...state,
      competitors: action.payload,
    };
  }
  if (action.type === "UPDATE_CATEGORY_DIMENSIONS") {
    return {
      ...state,
      category_dimensions: action.payload,
    };
  }
  if (action.type === "UPDATE_BRAND_DESCRIPTION") {
    return {
      ...state,
      brand_description: action.payload,
    };
  }
  if (action.type === "UPDATE_BRAND_SNIPPETS") {
    return {
      ...state,
      brand_snippets: action.payload,
    };
  }
  if (action.type === "UPDATE_POSITVE_NEGATIVE_ATTRIBUTES") {
    return {
      ...state,
      positive_negative_attributes: action.payload,
    };
  }
  if (action.type === "UPDATE_TOP_PRODUCTS") {
    return {
      ...state,
      top_products: action.payload,
    };
  }
  if (action.type === "UPDATE_ENTITY") {
    return {
      // ...INITIAL_STATE,
      ...state,
      entity: action.payload,
    };
  }
  if (action.type === "UPDATE_LAUNCHED") {
    return {
      ...state,
      is_launched: action.payload,
    };
  }
  if (action.type === "UPDATE_STATE") {
    return {
      ...action.payload,
    };
  }
  if (action.type === "RESET_STATE") {
    return INITIAL_STATE;
  }
  if (action.type === "UPDATE_BRAND_THEMES") {
    return {
      ...state,
      brand_themes: action.payload,
    };
  }
  if (action.type === "UPDATE_NAME") {
    return {
      ...state,
      name: action.payload,
    };
  }
  if (action.type === "UPDATE_CATEGORY") {
    return {
      ...state,
      category: action.payload,
    };
  }
  if (action.type === "UPDATE_MARKET") {
    return {
      ...state,
      market: action.payload,
    };
  }
  if (action.type === "UPDATE_LLM") {
    return {
      ...state,
      llm: action.payload,
    };
  }
  if (action.type === "UPDATE_TYPE") {
    return {
      ...state,
      type: action.payload,
    };
  }
  if (action.type === "UPDATE_TAXONOMY") {
    return {
      ...state,
      taxonomies: action.payload,
    };
  }
  if(action.type === "UPDATE_ID") {
    return {
      ...state,
      id: action.payload
    }
  }
  if(action.type === "ABORT_LAUNCH") {
    return {
      ...state,
      abort_launch: action.payload,
    }
  }
  return state;
}

export default function DiscoveryProvider({ children }) {
  const [state, dispatch] = useReducer(discoveryReducer, INITIAL_STATE);
  const [loading, setLoading] = useState(false);

  const { authUserEmail } = useContext(AuthContext);

  function updateBrandScores(payload) {
    dispatch({ type: "UPDATE_BRAND_SCORE", payload });
  }

  function updateSources(payload) {
    dispatch({ type: "UPDATE_SOURCES", payload });
  }

  function updateCoreAttributes(payload) {
    dispatch({ type: "UPDATE_CORE_ATTRIBUTES", payload });
  }

  function updatedCompetitors(payload) {
    dispatch({ type: "UPDATE_COMPETITORS", payload });
  }

  function updateCategoryDimensions(payload) {
    dispatch({ type: "UPDATE_CATEGORY_DIMENSIONS", payload });
  }

  function updateBrandDescription(payload) {
    dispatch({ type: "UPDATE_BRAND_DESCRIPTION", payload });
  }

  function updateBrandSnippets(payload) {
    dispatch({ type: "UPDATE_BRAND_SNIPPETS", payload });
  }

  function updatePositiveNegativeAttributes(payload) {
    dispatch({ type: "UPDATE_POSITVE_NEGATIVE_ATTRIBUTES", payload });
  }

  function updatedTopProducts(payload) {
    dispatch({ type: "UPDATE_TOP_PRODUCTS", payload });
  }

  function updateEntity(payload) {
    dispatch({ type: "UPDATE_ENTITY", payload });
  }

  function updatedLaunched(payload) {
    dispatch({ type: "UPDATE_LAUNCHED", payload });
  }

  function updateEntireState(payload) {
    dispatch({ type: "UPDATE_STATE", payload });
  }

  function resetState() {
    dispatch({ type: "RESET_STATE" });
  }

  function updatedBrandThemes(payload) {
    dispatch({ type: "UPDATE_BRAND_THEMES", payload });
  }

  function updateName(payload) {
    dispatch({ type: "UPDATE_NAME", payload });
  }

  function updateCategory(payload) {
    dispatch({ type: "UPDATE_CATEGORY", payload });
  }

  function updateMarket(payload) {
    dispatch({ type: "UPDATE_MARKET", payload });
  }

  function updateLLM(payload) {
    dispatch({ type: "UPDATE_LLM", payload });
  }

  function updateType(payload) {
    dispatch({ type: "UPDATE_TYPE", payload });
  }

  function updatedTaxonomies(payload) {
    dispatch({ type: "UPDATE_TAXONOMY", payload });
  }

  function updateAbortLaunch(payload) {
    dispatch({ type: "ABORT_LAUNCH", payload })

    setTimeout(()=>{
      setLoading(false);
      updatedLaunched(false);
      resetState();
    },100)
  }

  useEffect(() => {
    const type = state.type ?? "brand";
    if (hasData(state, type)) {
      setLoading(false);
      if (!state?.id) {
        console.log("LOG ✅ GOT DATA", state);
        const sanitizedData = sanitizeObject(state);
        addDataToFirebase("discovery_history", {
          ...sanitizedData,
          name: `${state.entity} ${state?.type} Overview`,
          date: Date.now(),
          authUserEmail,
        }).then(res=>{
          dispatch({ type: "UPDATE_ID", payload: res.key });
        })
      }
    } else {
      if(state.id) {
        setLoading(false);
      } else if(state.is_launched) {
        setLoading(true);
      }
    }
  }, [state, authUserEmail]);

  return (
    <DiscoveryContext.Provider
      value={{
        state: state,
        loading: loading,
        brand_scores: state.brand_scores,
        sources: state.sources,
        core_attributes: state.core_attributes,
        competitors: state.competitors,
        category_dimensions: state.category_dimensions,
        brand_description: state.brand_description,
        brand_snippets: state.brand_snippets,
        brand_themes: state.brand_themes,
        positive_negative_attributes: state.positive_negative_attributes,
        top_products: state.top_products,
        entity: state.entity,
        name: state.name,
        category: state.category,
        market: state.market,
        llm: state.llm,
        is_launched: state.is_launched,
        taxonomies: state.taxonomies,
        type: state.type,
        abort_launch: state.abort_launch,
        updateBrandScores,
        updateSources,
        updateCoreAttributes,
        updatedCompetitors,
        updateCategoryDimensions,
        updateBrandDescription,
        updateBrandSnippets,
        updatePositiveNegativeAttributes,
        updatedTopProducts,
        updateEntity,
        updateName,
        updatedLaunched,
        updateEntireState,
        resetState,
        updatedBrandThemes,
        updateCategory,
        updateMarket,
        updateLLM,
        updateType,
        updatedTaxonomies,
        updateAbortLaunch
      }}
    >
      {children}
    </DiscoveryContext.Provider>
  );
}
