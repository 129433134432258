import { Fragment, useState } from "react";
import {
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  MenuList,
  Badge,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import AddIcon from "@mui/icons-material/Add";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import ClearIcon from '@mui/icons-material/Clear';

import classes from "./Filter.module.css";

export default function Filter({ onSelectMenu, isFilterSet }) {
  const [anchorEl, setAnchorEl] = useState();
  const open = Boolean(anchorEl);

  function handleFilterClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMenuSelect(menu) {
    setAnchorEl(null);
    if (!menu) return;
    onSelectMenu(menu);
  }

  return (
    <Fragment>
      <div className={classes.filter_btn}>
        <Badge color="secondary" variant="dot" invisible={!isFilterSet} onClick={handleFilterClick}>
          <FilterAltOutlinedIcon />
        </Badge>
      </div>
      <Menu
        anchorEl={anchorEl}
        open={open}
        anchorOrigin={{ horizontal: "left", vertical: "top" }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        onClose={() => handleMenuSelect()}
      >
        <MenuList dense sx={{ width: 180 }}>
          <MenuItem dense onClick={() => handleMenuSelect("add-new")}>
            <ListItemIcon>
              <AddIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Add New Filter</ListItemText>
          </MenuItem>
          <MenuItem dense onClick={() => handleMenuSelect("filter-list")}>
            <ListItemIcon>
              <FilterListOutlinedIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>All Filters</ListItemText>
          </MenuItem>
          <MenuItem disabled={!isFilterSet} dense onClick={() => handleMenuSelect("reset")}>
            <ListItemIcon>
              <ClearIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Clear Filter</ListItemText>
          </MenuItem>
        </MenuList>
      </Menu>
    </Fragment>
  );
}
