import * as React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import TextInput from "../TextInput/TextInput";

export default function MUIAutocomplete({ onChange, onInputChange, label, options, ...props }) {
  return (
    <Autocomplete
      id="autocomplete"
      freeSolo
      size="small"
      onChange={(_, value )=> {
        const option = options.find(opt=> opt.name === value);
        onChange(option);
      }}
      onInputChange={(e)=> onInputChange(e.target.value)}
      options={options.map((option) => option.name)}
      renderInput={(params) => <TextField {...params} label={label} />}
      {...props}
    />
  );
}
