export const FETCH_BRANDS_REQUEST = "FETCH_BRANDS_REQUEST";
export const FETCH_BRANDS_SUCCESS = "FETCH_BRANDS_SUCCESS";
export const FETCH_BRANDS_FAILURE = "FETCH_BRANDS_FAILURE";

export const CREATE_BRAND_REQUEST = "CREATE_BRAND_REQUEST";
export const CREATE_BRAND_SUCCESS = "CREATE_BRAND_SUCCESS";
export const CREATE_BRAND_FAILURE = "CREATE_BRAND_FAILURE";

export const UPDATE_BRAND_REQUEST = "UPDATE_BRAND_REQUEST";
export const UPDATE_BRAND_SUCCESS = "UPDATE_BRAND_SUCCESS";
export const UPDATE_BRAND_FAILURE = "UPDATE_BRAND_FAILURE";

export const DELETE_BRAND_REQUEST = "DELETE_BRAND_REQUEST";
export const DELETE_BRAND_SUCCESS = "DELETE_BRAND_SUCCESS";
export const DELETE_BRAND_FAILURE = "DELETE_BRAND_FAILURE";

export const FETCH_BRAND_PRODUCTS_REQUEST = "FETCH_BRAND_PRODUCTS_REQUEST";
export const FETCH_BRAND_PRODUCTS_SUCCESS = "FETCH_BRAND_PRODUCTS_SUCCESS";
export const FETCH_BRAND_PRODUCTS_FAILURE = "FETCH_BRAND_PRODUCTS_FAILURE";

export const CREATE_BRAND_PRODUCT_REQUEST = "CREATE_BRAND_PRODUCT_REQUEST";
export const CREATE_BRAND_PRODUCT_SUCCESS = "CREATE_BRAND_PRODUCT_SUCCESS";
export const CREATE_BRAND_PRODUCT_FAILURE = "CREATE_BRAND_PRODUCT_FAILURE";

export const UPDATE_BRAND_PRODUCT_REQUEST = "UPDATE_BRAND_PRODUCT_REQUEST";
export const UPDATE_BRAND_PRODUCT_SUCCESS = "UPDATE_BRAND_PRODUCT_SUCCESS";
export const UPDATE_BRAND_PRODUCT_FAILURE = "UPDATE_BRAND_PRODUCT_FAILURE";

export const DELETE_BRAND_PRODUCT_REQUEST = "DELETE_BRAND_PRODUCT_REQUEST";
export const DELETE_BRAND_PRODUCT_SUCCESS = "DELETE_BRAND_PRODUCT_SUCCESS";
export const DELETE_BRAND_PRODUCT_FAILURE = "DELETE_BRAND_PRODUCT_FAILURE";

// Brand Categories
export const FETCH_BRAND_CATEGORIES_REQUEST = "FETCH_BRAND_CATEGORIES_REQUEST";
export const FETCH_BRAND_CATEGORIES_SUCCESS = "FETCH_BRAND_CATEGORIES_SUCCESS";
export const FETCH_BRAND_CATEGORIES_FAILURE = "FETCH_BRAND_CATEGORIES_FAILURE";

export const FETCH_BRAND_CATEGORY_REQUEST = "FETCH_BRAND_CATEGORY_REQUEST";
export const FETCH_BRAND_CATEGORY_SUCCESS = "FETCH_BRAND_CATEGORY_SUCCESS";
export const FETCH_BRAND_CATEGORY_FAILURE = "FETCH_BRAND_CATEGORY_FAILURE";

export const CREATE_BRAND_CATEGORY_REQUEST = "CREATE_BRAND_CATEGORY_REQUEST";
export const CREATE_BRAND_CATEGORY_SUCCESS = "CREATE_BRAND_CATEGORY_SUCCESS";
export const CREATE_BRAND_CATEGORY_FAILURE = "CREATE_BRAND_CATEGORY_FAILURE";

export const UPDATE_BRAND_CATEGORY_REQUEST = "UPDATE_BRAND_CATEGORY_REQUEST";
export const UPDATE_BRAND_CATEGORY_SUCCESS = "UPDATE_BRAND_CATEGORY_SUCCESS";
export const UPDATE_BRAND_CATEGORY_FAILURE = "UPDATE_BRAND_CATEGORY_FAILURE";

export const DELETE_BRAND_CATEGORY_REQUEST = "DELETE_BRAND_CATEGORY_REQUEST";
export const DELETE_BRAND_CATEGORY_SUCCESS = "DELETE_BRAND_CATEGORY_SUCCESS";
export const DELETE_BRAND_CATEGORY_FAILURE = "DELETE_BRAND_CATEGORY_FAILURE";

export const FETCH_PERSONAS_REQUEST = "FETCH_PERSONAS_REQUEST";
export const FETCH_PERSONAS_SUCCESS = "FETCH_PERSONAS_SUCCESS";
export const FETCH_PERSONAS_FAILURE = "FETCH_PERSONAS_FAILURE";

export const CREATE_PERSONA_REQUEST = "CREATE_PERSONA_REQUEST";
export const CREATE_PERSONA_SUCCESS = "CREATE_PERSONA_SUCCESS";
export const CREATE_PERSONA_FAILURE = "CREATE_PERSONA_FAILURE";

export const UPDATE_PERSONA_REQUEST = "UPDATE_PERSONA_REQUEST";
export const UPDATE_PERSONA_SUCCESS = "UPDATE_PERSONA_SUCCESS";
export const UPDATE_PERSONA_FAILURE = "UPDATE_PERSONA_FAILURE";

export const DELETE_PERSONA_REQUEST = "DELETE_PERSONA_REQUEST";
export const DELETE_PERSONA_SUCCESS = "DELETE_PERSONA_SUCCESS";
export const DELETE_PERSONA_FAILURE = "DELETE_PERSONA_FAILURE";

// Competitor Brands
export const FETCH_COMPETITOR_BRANDS_REQUEST =
  "FETCH_COMPETITOR_BRANDS_REQUEST";
export const FETCH_COMPETITOR_BRANDS_SUCCESS =
  "FETCH_COMPETITOR_BRANDS_SUCCESS";
export const FETCH_COMPETITOR_BRANDS_FAILURE =
  "FETCH_COMPETITOR_BRANDS_FAILURE";

export const FETCH_COMPETITOR_BRAND_REQUEST = "FETCH_COMPETITOR_BRAND_REQUEST";
export const FETCH_COMPETITOR_BRAND_SUCCESS = "FETCH_COMPETITOR_BRAND_SUCCESS";
export const FETCH_COMPETITOR_BRAND_FAILURE = "FETCH_COMPETITOR_BRAND_FAILURE";

export const CREATE_COMPETITOR_BRAND_REQUEST =
  "CREATE_COMPETITOR_BRAND_REQUEST";
export const CREATE_COMPETITOR_BRAND_SUCCESS =
  "CREATE_COMPETITOR_BRAND_SUCCESS";
export const CREATE_COMPETITOR_BRAND_FAILURE =
  "CREATE_COMPETITOR_BRAND_FAILURE";

export const UPDATE_COMPETITOR_BRAND_REQUEST =
  "UPDATE_COMPETITOR_BRAND_REQUEST";
export const UPDATE_COMPETITOR_BRAND_SUCCESS =
  "UPDATE_COMPETITOR_BRAND_SUCCESS";
export const UPDATE_COMPETITOR_BRAND_FAILURE =
  "UPDATE_COMPETITOR_BRAND_FAILURE";

export const DELETE_COMPETITOR_BRAND_REQUEST =
  "DELETE_COMPETITOR_BRAND_REQUEST";
export const DELETE_COMPETITOR_BRAND_SUCCESS =
  "DELETE_COMPETITOR_BRAND_SUCCESS";
export const DELETE_COMPETITOR_BRAND_FAILURE =
  "DELETE_COMPETITOR_BRAND_FAILURE";

// Competitor Products
export const FETCH_COMPETITOR_PRODUCTS_REQUEST =
  "FETCH_COMPETITOR_PRODUCTS_REQUEST";
export const FETCH_COMPETITOR_PRODUCTS_SUCCESS =
  "FETCH_COMPETITOR_PRODUCTS_SUCCESS";
export const FETCH_COMPETITOR_PRODUCTS_FAILURE =
  "FETCH_COMPETITOR_PRODUCTS_FAILURE";

export const FETCH_COMPETITOR_PRODUCT_REQUEST =
  "FETCH_COMPETITOR_PRODUCT_REQUEST";
export const FETCH_COMPETITOR_PRODUCT_SUCCESS =
  "FETCH_COMPETITOR_PRODUCT_SUCCESS";
export const FETCH_COMPETITOR_PRODUCT_FAILURE =
  "FETCH_COMPETITOR_PRODUCT_FAILURE";

export const CREATE_COMPETITOR_PRODUCT_REQUEST =
  "CREATE_COMPETITOR_PRODUCT_REQUEST";
export const CREATE_COMPETITOR_PRODUCT_SUCCESS =
  "CREATE_COMPETITOR_PRODUCT_SUCCESS";
export const CREATE_COMPETITOR_PRODUCT_FAILURE =
  "CREATE_COMPETITOR_PRODUCT_FAILURE";

export const UPDATE_COMPETITOR_PRODUCT_REQUEST =
  "UPDATE_COMPETITOR_PRODUCT_REQUEST";
export const UPDATE_COMPETITOR_PRODUCT_SUCCESS =
  "UPDATE_COMPETITOR_PRODUCT_SUCCESS";
export const UPDATE_COMPETITOR_PRODUCT_FAILURE =
  "UPDATE_COMPETITOR_PRODUCT_FAILURE";

export const DELETE_COMPETITOR_PRODUCT_REQUEST =
  "DELETE_COMPETITOR_PRODUCT_REQUEST";
export const DELETE_COMPETITOR_PRODUCT_SUCCESS =
  "DELETE_COMPETITOR_PRODUCT_SUCCESS";
export const DELETE_COMPETITOR_PRODUCT_FAILURE =
  "DELETE_COMPETITOR_PRODUCT_FAILURE";

// product-categories

export const FETCH_PRODUCT_CATEGORIES_REQUEST =
  "FETCH_PRODUCT_CATEGORIES_REQUEST";
export const FETCH_PRODUCT_CATEGORIES_SUCCESS =
  "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_PRODUCT_CATEGORIES_FAILURE =
  "FETCH_PRODUCT_CATEGORIES_FAILURE";

export const FETCH_PRODUCT_CATEGORY_REQUEST = "FETCH_PRODUCT_CATEGORY_REQUEST";
export const FETCH_PRODUCT_CATEGORY_SUCCESS = "FETCH_PRODUCT_CATEGORY_SUCCESS";
export const FETCH_PRODUCT_CATEGORY_FAILURE = "FETCH_PRODUCT_CATEGORY_FAILURE";

export const CREATE_PRODUCT_CATEGORY_REQUEST =
  "CREATE_PRODUCT_CATEGORY_REQUEST";
export const CREATE_PRODUCT_CATEGORY_SUCCESS =
  "CREATE_PRODUCT_CATEGORY_SUCCESS";
export const CREATE_PRODUCT_CATEGORY_FAILURE =
  "CREATE_PRODUCT_CATEGORY_FAILURE";

export const UPDATE_PRODUCT_CATEGORY_REQUEST =
  "UPDATE_PRODUCT_CATEGORY_REQUEST";
export const UPDATE_PRODUCT_CATEGORY_SUCCESS =
  "UPDATE_PRODUCT_CATEGORY_SUCCESS";
export const UPDATE_PRODUCT_CATEGORY_FAILURE =
  "UPDATE_PRODUCT_CATEGORY_FAILURE";

export const DELETE_PRODUCT_CATEGORY_REQUEST =
  "DELETE_PRODUCT_CATEGORY_REQUEST";
export const DELETE_PRODUCT_CATEGORY_SUCCESS =
  "DELETE_PRODUCT_CATEGORY_SUCCESS";
export const DELETE_PRODUCT_CATEGORY_FAILURE =
  "DELETE_PRODUCT_CATEGORY_FAILURE";

export const SET_USERS = "SET_USERS";
export const FETCH_USER_STARTED = "FETCH_USER_STARTED";
export const FETCH_USER_FAILED = "FETCH_USER_FAILED";
export const DELETE_USER_STARTED = "DELETE_USER_STARTED";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILED = "DELETE_USER_FAILED";
export const UPDATE_USER_STARTED = "UPDATE_USER_STARTED";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILED = "UPDATE_USER_FAILED";
export const ADD_USER_STARTED = "ADD_USER_STARTED";
export const ADD_USER_FAILED = "ADD_USER_FAILED";
export const ADD_USER_SUCCESS = "ADD_USER_SUCCESS";

// global-categories

export const FETCH_CATEGORIES_REQUEST = "FETCH_PRODUCT_CATEGORIES_REQUEST";
export const FETCH_CATEGORIES_SUCCESS = "FETCH_PRODUCT_CATEGORIES_SUCCESS";
export const FETCH_CATEGORIES_FAILURE = "FETCH_PRODUCT_CATEGORIES_FAILURE";

// global-core-attributes

export const FETCH_CORE_ATTRIBUTES_REQUEST =
  "FETCH_PRODUCT_CORE_ATTRIBUTES_REQUEST";
export const FETCH_CORE_ATTRIBUTES_SUCCESS =
  "FETCH_PRODUCT_CORE_ATTRIBUTES_SUCCESS";
export const FETCH_CORE_ATTRIBUTES_FAILURE =
  "FETCH_PRODUCT_CORE_ATTRIBUTES_FAILURE";

// Geo-market actions

export const FETCH_GEOMARKETS_REQUEST = "FETCH_GEOMARKETS_REQUEST";
export const FETCH_GEOMARKETS_SUCCESS = "FETCH_GEOMARKETS_SUCCESS";
export const FETCH_GEOMARKETS_FAILURE = "FETCH_GEOMARKETS_FAILURE";

export const CREATE_GEOMARKET_REQUEST = "CREATE_GEOMARKET_REQUEST";
export const CREATE_GEOMARKET_SUCCESS = "CREATE_GEOMARKET_SUCCESS";
export const CREATE_GEOMARKET_FAILURE = "CREATE_GEOMARKET_FAILURE";

export const UPDATE_GEOMARKET_REQUEST = "UPDATE_GEOMARKET_REQUEST";
export const UPDATE_GEOMARKET_SUCCESS = "UPDATE_GEOMARKET_SUCCESS";
export const UPDATE_GEOMARKET_FAILURE = "UPDATE_GEOMARKET_FAILURE";

export const DELETE_GEOMARKET_REQUEST = "DELETE_GEOMARKET_REQUEST";
export const DELETE_GEOMARKET_SUCCESS = "DELETE_GEOMARKET_SUCCESS";
export const DELETE_GEOMARKET_FAILURE = "DELETE_GEOMARKET_FAILURE";

// Customer Actions

export const FETCH_CUSTOMER_REQUEST = "FETCH_CUSTOMER_REQUEST";
export const FETCH_CUSTOMER_SUCCESS = "FETCH_CUSTOMER_SUCCESS";
export const FETCH_CUSTOMER_FAILURE = "FETCH_CUSTOMER_FAILURE";

export const UPDATE_CUSTOMER_REQUEST = "UPDATE_CUSTOMER_REQUEST";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const FETCH_ACTION_METRICS_REQUEST = "FETCH_ACTION_METRICS_REQUEST";
export const FETCH_ACTION_METRICS_SUCCESS = "FETCH_ACTION_METRICS_SUCCESS";
export const FETCH_ACTION_METRICS_FAILURE = "FETCH_ACTION_METRICS_FAILURE";
export const CREATE_ACTION_METRIC_REQUEST = "CREATE_ACTION_METRIC_REQUEST";
export const CREATE_ACTION_METRIC_SUCCESS = "CREATE_ACTION_METRIC_SUCCESS";
export const CREATE_ACTION_METRIC_FAILURE = "CREATE_ACTION_METRIC_FAILURE";
export const UPDATE_ACTION_METRIC_REQUEST = "UPDATE_ACTION_METRIC_REQUEST";
export const UPDATE_ACTION_METRIC_SUCCESS = "UPDATE_ACTION_METRIC_SUCCESS";
export const UPDATE_ACTION_METRIC_FAILURE = "UPDATE_ACTION_METRIC_FAILURE";
export const DELETE_ACTION_METRIC_REQUEST = "DELETE_ACTION_METRIC_REQUEST";
export const DELETE_ACTION_METRIC_SUCCESS = "DELETE_ACTION_METRIC_SUCCESS";
export const DELETE_ACTION_METRIC_FAILURE = "DELETE_ACTION_METRIC_FAILURE";

// logout-action

export const LOGOUT = "LOGOUT";
