import classes from "./Sources.module.css";

export default function Sources({ sources, showSnippets }) {
  return (
    <ul className={classes.source_list}>
      {sources.map((source, index) => {
        return (
          <li className={classes.source_item} key={index}>
            {showSnippets && <div>{source.snippet}</div>}
            <a target="_blank" href={source.source}>
              {source.source}
            </a>
          </li>
        );
      })}
    </ul>
  );
}
