import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import classes from "./Alert.module.css";

export default function Alert({ title, message }) {
  return (
    <div className={classes.container}>
      <div className={classes.icon}>
        <InfoOutlinedIcon />
      </div>
      <div className={classes.content}>
        <InfoOutlinedIcon className={classes.bgIcon}/>
        <div className={classes.title}>{ title }</div>
        <div className={classes.message}>
          { message }
        </div>
      </div>
    </div>
  );
}
