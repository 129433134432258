import { Skeleton } from "@mui/material";

export default function LoadingSkeleton() {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", padding: "5px 0" }}
    >
      <Skeleton
        width={"100%"}
        className="mb-2"
        height={5}
        variant="rectangular"
        style={{ background: "gray" }}
      />
      <Skeleton
        width={"100%"}
        className="mb-2"
        height={5}
        variant="rectangular"
        style={{ background: "gray" }}
      />
      <Skeleton
        width={"100%"}
        height={5}
        variant="rectangular"
        style={{ background: "gray" }}
      />
    </div>
  );
}
