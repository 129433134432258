import { useRef, useContext, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import api from "../../../../BrandOnBoarding/api";
import Modal from "../../../../Component/Modal/Modal";
import TextInput from "../../../../Component/UI/TextInput/TextInput";
import { AuthContext } from "../../../../context/auth-context";
import classes from "./AddFilter.module.css";

export default function AddFilter({ config, open, onClose, type = "brand" }) {
  const [loading, setLoading] = useState(false);
  const nameInputRef = useRef();
  const descriptionInputRef = useRef();

  const { user } = useContext(AuthContext);

  async function handleSubmit() {
    try {
      setLoading(true);
      const response = await api.post(`/users/search-filter/${user.id}`, {
        name: nameInputRef.current.value,
        description: descriptionInputRef.current.value,
        type: type,
        config: config
      });
      if (response.status === 201) {
        toast.success("Search filter added successfully");
        onClose();
      } else {
        toast.error("Failed to add search filter");
        onClose();
      }
    } catch (error) {
      toast.error("Failed to add search filter");
      onClose();
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <ToastContainer />
      <Modal
        title="Add Filter"
        open={open}
        handleClose={onClose}
        showActions={!loading}
        confirmBtnText="Save"
        actionsClassName={classes.actions}
        handleConfirm={handleSubmit}
      >
        <form className={classes.form}>
          <TextInput className="mb-4" label="Name" ref={nameInputRef} />
          <TextInput label="Description (optional)" ref={descriptionInputRef} />
          {loading && <div className={classes.loading}>Please wait...</div>}
        </form>
      </Modal>
    </>
  );
}
